import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const rapidScaleWelcomeDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    rapidScaleWelcomeDialogContainer: {
      padding: '30px',
      fontFamily: 'CeraPro-Medium'
    },
    rapidScaleWelcomeDialogBanner: {
      textAlign: 'center',
      marginBottom: '20px'
    },
    rapidScaleWelcomeDialogHeader: {
      lineHeight: '30px',
      fontSize: '20px',
      color: '#084394 '
    },
    rapidScaleWelcomeDialogP1: {
      lineHeight: '27px',
      fontSize: '16px', 
      fontFamily: 'CeraPro-Regular',
      color: '#4B4B4B'
    },
    rapidScaleWelcomeDialogP2: {
      lineHeight: '27px',
      fontSize: '16px',
      color: '#4B4B4B'
    },
    rapidScaleWelcomeDialogLink: {
      lineHeight: '27px',
      fontFamily: 'Roboto',
      fontSize: '16px',
      color: '#0D70F7'
    },
    rapidScaleWelcomeDialogCheckboxRow: {
      fontFamily: 'CeraPro-Regular',
    },
    rapidScaleWelcomeDialogButtons: {
      textAlign: 'right'
    }
  }),
);

export default rapidScaleWelcomeDialogStyles;
