import { getCookie } from '../lib/helpers';
import {
  GET_PERMISSIONS, UPDATE_TIMESTAMP,
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_DETAILS,
  GET_NOTIFICATION_LAST_SCANS,
  SET_DEVICE_TYPE,
  OPEN_MODAL, CLOSE_MODAL,
  SET_IMPERSONATION_INFO,
  UNSET_IMPERSONATION_INFO,
  GET_CLOUD_ACCOUNT_INFO,
  UPDATE_CLOUD_ACCOUNT_INFO,
  FAILED_CLOUD_ACCOUNT_UPDATE,
  CLEAR_CLOUD_ACCOUNT_UPDATE,
  OPEN_DLP_MODAL, CLOSE_DLP_MODAL,
  SET_DLP_DATA_LOADING,
  SET_COST_DATA_AVAILABILITY,
  SET_EARLIEST_COST_DATE,
  SET_BASE_USER_INFORMATION,
  SET_OKTA_ID
} from './types';
import { SecurityOptions } from '../types';
import { getRequestConfig } from '../lib/auth';
import { inspectAndFetch } from '../utilities/api';
import { inspect } from 'util';


export const getPermissions = () => {
  return async (dispatch) => {
    const config = getRequestConfig()
    //const response = await fetch('/api/getpermissions/', config);
    const response = await inspectAndFetch('/api/getpermissions/', config);
    const data = await response.json();

    dispatch({
      type: GET_PERMISSIONS,
      payload: data
    })
  }
}

export const getNotifications = () => {
  return async (dispatch) => {
    const config = getRequestConfig()
    //const response = await fetch('/api/getnotifications/', config);
    const response = await inspectAndFetch('/api/getnotifications/', config);
    const data = await response.json();
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: {...data }
    })
  }
}

export const getNotificationDetails = () => {
  return async (dispatch) => {
    const config = getRequestConfig()
    //const response = await fetch(`/api/get_notification_details/`, config);
    const response = await inspectAndFetch(`/api/get_notification_details/`, config);
    const data = await response.json();
    dispatch({
      type: GET_NOTIFICATION_DETAILS,
      payload: data
    })
  }
}

export const getNotificationLastScans = () => {
  return async (dispatch) => {
    const config = getRequestConfig()
    // const response = await fetch('/api/getnotifications_scan_times/', config);
    const response = await inspectAndFetch('/api/getnotifications_scan_times/', config);
    const data = await response.json();

    dispatch({
      type: GET_NOTIFICATION_LAST_SCANS,
      payload: data
    })
  }
}

export const setImpersonationInfo = (user_id: number, name: string) => {
  return async (dispatch) => {
    const config = getRequestConfig()
    //const response = await fetch(`/impersonate/${user_id}/`, config);
    const response = await inspectAndFetch('/impersonate/${user_id}/', config);
    dispatch({
      type: SET_IMPERSONATION_INFO,
      payload: name
    })
  }
}

export const unsetImpersonationInfo = () => {
  return async (dispatch) => {
    const config = getRequestConfig()
    //const response = await fetch(`/impersonate/stop/`, config);
    const response = await inspectAndFetch('impersonate/stop/', config);
    dispatch({
      type: UNSET_IMPERSONATION_INFO,
    })
  }
}

export const setDeviceType = () => {
  return {
    type: SET_DEVICE_TYPE,
    payload: null
  }
}

export const openModal = () => {
  return {
    type: OPEN_MODAL,
    payload: true
  }
}


export const closeModal = () => {
  return {
    type: CLOSE_MODAL,
    payload: false
  }
}

export const updateTimestamp = () => {
  return {
    type: UPDATE_TIMESTAMP,
    payload: null
  }
}

export const setDLPDataLoading = () => {
  return {
    type: SET_DLP_DATA_LOADING,
    payload: true
  }
}

export const loadCloudCostDataAsync = () => {
  return async () => {
    const config = getRequestConfig()
    fetch(`/api/customer-cost-report/week-over-week/`, config)
  }
}


export const getCloudAccountInfo = (sf_id: string) => {
  return async (dispatch) => {
    const config = getRequestConfig()
    const response = await inspectAndFetch(`/api/cloud-accounts-dlp/${sf_id}/`, config);
    const data = await response.json();

    dispatch({
      type: GET_CLOUD_ACCOUNT_INFO,
      payload: data
    })
  }
}

export const updateCloudAccountInfo = (url: string, data: Object, index: number) => {
  return async (dispatch) => {
    let csrfToken: string = getCookie('csrftoken') || '';
    const _config: RequestInit = {
      method: 'PATCH',
      headers: {
        'X-CSRFToken': csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(data)
    };
    try {
      const response = await inspectAndFetch(`${url}`, _config);
      const data = await response.json();
      dispatch({
        type: UPDATE_CLOUD_ACCOUNT_INFO,
        payload: { ...data, index }
      })
    }
    catch (e) {
      dispatch({
        type: FAILED_CLOUD_ACCOUNT_UPDATE,
        payload: index
      })
    }
  }
}

export const clearCloudAccountUpdate = () => {
  return {
    type: CLEAR_CLOUD_ACCOUNT_UPDATE,
    payload: null
  }
}

export const openDLPModal = (index: number, securityOptions: SecurityOptions, value?: number | null) => {
  return {
    type: OPEN_DLP_MODAL,
    payload: { index, securityOptions, value }
  }
}

export const closeDLPModal = () => {
  return {
    type: CLOSE_DLP_MODAL,
    payload: null
  }
}

export const getCostDataAvailability = () => {
  return async (dispatch) => {
    const config = getRequestConfig()
    const response = await inspectAndFetch('/api/cost_data_available/', config);
    const data = await response.json();

    dispatch({
      type: SET_COST_DATA_AVAILABILITY,
      payload: data
    })
  }
}

export const getEarliestCostDate = () => {
  return async (dispatch) => {
    const config = getRequestConfig()
    const response = await inspectAndFetch('/api/customer-cost-report/earliest-cost-date/', config);
    const data = await response.json();

    dispatch({
      type: SET_EARLIEST_COST_DATE,
      payload: data
    })
  }
}

export const setBaseUserInformation = (baseInfo) => {
  console.log(baseInfo)
  return {
    type: SET_BASE_USER_INFORMATION,
    payload: baseInfo
  }
}

export const setOktaId = (okta_id: string) => {
  return {
    type: SET_OKTA_ID,
    payload: okta_id
  }
}