import * as React from 'react'; 
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import store from './store';

import Authentication from './hocs/Authentication';
import { FlagProvider } from '@unleash/proxy-client-react';
// import { BrowserTracing } from "@sentry/tracing";
// import * as Sentry from "@sentry/react";

// enable is_impersonating variable to be defined on window object
declare global {
  interface Window {
    CLIENT_ENV: any
  }
}

// if (!process.env.LOCAL_DEVELOPMENT) {
//   /**
//    * About tracesSampleRate [0 - 1.0, where 1.0 is 100% of errors sent]
//     While testing, it is okay to keep the tracesSampleRate as 1.0. 
//     This means that every action performed in the browser will be sent as a transaction to Sentry.
//     In production, this value should be lowered to collect a uniform sample data size without reaching Sentry’s transaction quota. 
//     Alternatively, to collect sample data dynamically, tracesSampler can be used to filter these transactions.

//     https://develop.sentry.dev/sdk/performance/#:~:text=tracing%20opt%2Din.-,tracesSampleRate,(all%20will%20be%20sent).
//    */
//   Sentry.init({
//     dsn: 'https://e81a64e0a2ae465ab535c51024918fe1@o517396.ingest.sentry.io/6067347',
//     integrations: [new BrowserTracing()],
//     sampleRate: 1.0, // 100% of errors are sent to Sentry
//     tracesSampleRate: 0.1, // 10% of transactions are sent to Sentry
//     environment: window.CLIENT_ENV,
//     release: window.VERSION
//   });
// }

const _ENV = window.CLIENT_ENV ? window.CLIENT_ENV : 'dev';
const featureFlagConfig = {
  url: `https://${_ENV}-console.unleash.aws.logicworks.net/proxy`, // or https://UNLEASH_HOSTNAME/api/frontend
  clientKey: '64BC0FD4-5841-451D-913D-CABDADE4E13B',
  refreshInterval: 15,
  appName: 'console',
  environment: _ENV,
  disableMetrics: true
};


class PulseApp extends React.Component<{}, {}> {
  render() {
    return (
      <FlagProvider config={featureFlagConfig}>
        <Provider store={store as any}>
          <Router basename="/">
            <Authentication />
          </Router>
        </Provider>
      </FlagProvider>
    )
  }
}


export default PulseApp;
