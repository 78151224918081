import * as React from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';

import { Notifications } from '@material-ui/icons';
import { List, ListItem } from '@material-ui/core';
import StyledTooltip from '../styled-components/StyledTooltip';
import sidebarStyles from './SidebarStyles';
import { Permissions } from '../types';
import { useFlag } from '@unleash/proxy-client-react';

declare global {
  interface Window {
    RAPIDSCALE_PORTAL_LINK: string
    ENVIRONMENT_NAME: string
  }
}


interface SidebarProps extends RouteComponentProps<any> {
  open: boolean
  handleSidebarOpen: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Sidebar = (props: SidebarProps) => {
  const classes = sidebarStyles();
  const impersonation = useSelector((state: RootStateOrAny) => state.home.impersonation)
  const accountTypes = useSelector((state: RootStateOrAny) => state.home.account_types)
  const permissions: Permissions = useSelector((state: RootStateOrAny) => state.home.permissions);
  const costDataAvailable = useSelector((state: RootStateOrAny) => state.home.cost_data_available);
  const feature_flag_pulse_user_access_enabled = useFlag('lw_pulse_user_access_enabled');


  let drawerContainerClass = impersonation ? classes.drawerPaperExpanded : classes.drawerPaper;

  const findLocation = (): string => {
    return props.location.pathname || '';
  }

  const getIntrusionDetectionLinks = (permissions: Permissions) => {
    if (permissions.threatstack && permissions.alertlogic) {
      return (
        <>
          <Link to="/threatstack" className={findLocation() == "/threatstack" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarThreatStack">
            <ListItem className={findLocation() == "/threatstack" ? classes.selectedSubListItem : classes.subListItem}>
              <StyledTooltip
                title={'Threat Stack'}
                placement="right"
                arrow={true}
              >
                <span>Threat Stack</span>
              </StyledTooltip>
            </ListItem>
          </Link>
          <Link to="/alertlogic" className={findLocation() == "/alertlogic" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarAlertLogic">
            <ListItem className={findLocation() == "/alertlogic" ? classes.selectedSubListItem : classes.subListItem}>
              <StyledTooltip
                title={'Alert Logic'}
                placement="right"
                arrow={true}
              >
                <span>Alert Logic</span>
              </StyledTooltip>
            </ListItem>
          </Link>
        </>
      )
    }
    else if (permissions.alertlogic && !permissions.threatstack) {
      return (
        <Link to="/alertlogic" className={findLocation() == "/alertlogic" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarAlertLogic">
          <ListItem className={findLocation() == "/alertlogic" ? classes.selectedSubListItem : classes.subListItem}>
            <StyledTooltip
              title={'Alert Logic'}
              placement="right"
              arrow={true}
            >
              <span>Intrusion Detection</span>
            </StyledTooltip>
          </ListItem>
        </Link>
      )
    }
    else {
      return (
        <Link to="/threatstack" className={findLocation() == "/threatstack" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarThreatStack">
          <ListItem className={findLocation() == "/threatstack" ? classes.selectedSubListItem : classes.subListItem}>
            <StyledTooltip
              title={'Threat Stack'}
              placement="right"
              arrow={true}
            >
              <span>Intrusion Detection</span>
            </StyledTooltip>
          </ListItem>
        </Link>
      )
    }
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={props.open}
      classes={{
        paper: drawerContainerClass,
      }}
    >
      <List className={classes.topList}>
        {(permissions.events || permissions.can_impersonate || permissions.client_settings || permissions.read_only_smdb_perm) &&
          <ListItem className={classes.listItem}>
            <div className={findLocation() == "/internal" ? classes.iconRowSelected : classes.iconRow}>
              <Link to="/" className={findLocation() == "/internal" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarSupport">
                <StyledTooltip
                  title={'Logicworks Internal'}
                  placement="right"
                  arrow={true}
                >
                  <span className={findLocation() == "/internal" ? classes.selectedListItemSpan : classes.listItemSpan}>
                    Logicworks Internal
                  </span>
                </StyledTooltip>
              </Link>
            </div>
            <List className={classes.subList}>
              {permissions.events &&
                <Link to="/events" className={findLocation() == "/events" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarAlarms">
                  <ListItem className={findLocation() == "/events" ? classes.selectedSubListItem : classes.subListItem}>
                    <StyledTooltip
                      title={'Pulse Alarm Monitoring'}
                      placement="right"
                      arrow={true}
                    >
                      <span>Pulse Alarm Monitoring</span>
                    </StyledTooltip>
                  </ListItem>
                </Link>}
              {((permissions.client_settings || permissions.read_only_smdb_perm) && window.waffle.SWITCHES['vpc_page_demo']) &&
                <Link to="/azure-service-map" className={findLocation() == "/azure-service-map" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarAlarms">
                  <ListItem className={findLocation() == "/azure-service-map" ? classes.selectedSubListItem : classes.subListItem}>
                    <StyledTooltip
                      title={'Azure Service Map'}
                      placement="right"
                      arrow={true}
                    >
                      <span>Azure Service Map</span>
                    </StyledTooltip>
                  </ListItem>
                </Link>}
              {permissions.can_impersonate &&
                <Link to="/impersonation-admin" className={findLocation() == "/impersonation-admin" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarAlarms">
                  <ListItem className={findLocation() == "/impersonation-admin" ? classes.selectedSubListItem : classes.subListItem}>
                    <StyledTooltip
                      title={'User Permissions'}
                      placement="right"
                      arrow={true}
                    >
                      <span>User Permissions</span>
                    </StyledTooltip>
                  </ListItem>
                </Link>}
            </List>
          </ListItem>}
        <ListItem className={findLocation() == "/" ? classes.selectedListItem : classes.listItem}>
          <Link to="/" className={findLocation() == "/" ? classes.selectedAnchorTag : classes.anchorTag}>
            <StyledTooltip
              title={'Home'}
              placement="right"
              arrow={true}
            >
              <span className={findLocation() == "/" ? classes.selectedListItemSpan : classes.listItemSpan}>
                Home
              </span>
            </StyledTooltip>
          </Link>
        </ListItem>
        <ListItem className={findLocation() == "/landscape" ? classes.selectedListItem : classes.listItem}>
          <Link to="/landscape" className={findLocation() == "/landscape" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarLandscape">
            <StyledTooltip
              title={'Landscape'}
              placement="right"
              arrow={true}
            >
              <span className={findLocation() == "/landscape" ? classes.selectedListItemSpan : classes.listItemSpan}>
                Landscape
              </span>
            </StyledTooltip>
          </Link>
        </ListItem>
        <ListItem className={classes.listItem}>
          <div className={findLocation() == "/security" ? classes.iconRowSelected : classes.iconRow}>
            <Link to="/security" className={findLocation() == "/security" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarSecurity">
              <StyledTooltip
                title={'Security'}
                placement="right"
                arrow={true}
              >
                <span className={findLocation() == "/security" ? classes.selectedListItemSpan : classes.listItemSpan}>
                  Security
                </span>
              </StyledTooltip>
            </Link>
          </div>
          <List className={classes.subList}>
            {
              accountTypes.includes('AWS') ?
                <Link to="/dlp" className={findLocation() == "/dlp" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarDlp">
                  <ListItem className={findLocation() == "/dlp" ? classes.selectedSubListItem : classes.subListItem}>
                    <StyledTooltip
                      title={'Data Loss Prevention for AWS'}
                      placement="right"
                      arrow={true}
                    >
                      <span>Data Loss Prevention for AWS</span>
                    </StyledTooltip>
                  </ListItem>
                </Link> : null
            }
            <Link to="/trendmicro" className={findLocation() == "/trendmicro" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarTrendMicro">
              <ListItem className={findLocation() == "/trendmicro" ? classes.selectedSubListItem : classes.subListItem}>
                <StyledTooltip
                  title={'Trend Micro'}
                  placement="right"
                  arrow={true}
                >
                  <span>Malware Detection</span>
                </StyledTooltip>
              </ListItem>
            </Link>
            {getIntrusionDetectionLinks(permissions)}
            {
              (accountTypes.includes('AWS') && permissions.notifications) ?
                <Link to="/security-notifications" className={findLocation() == "/security-notifications" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarSecurityNotifications">
                  <ListItem className={findLocation() == "/security-notifications" ? classes.selectedSubListItem : classes.subListItem}>
                    <StyledTooltip
                      title={'Security Notifications'}
                      placement="right"
                      arrow={true}
                    >
                      <span className={classes.notificationsItem}>
                        <span style={{ paddingRight: '5px' }}>Notifications</span>
                        <Notifications fontSize="small" />
                      </span>
                    </StyledTooltip>
                  </ListItem>
                </Link> :
                null
            }
          </List>
        </ListItem>
        {accountTypes.includes('AWS') &&
          <ListItem className={classes.listItem} id="sidebar_operations">
            <div className={findLocation() == "/operations" ? classes.iconRowSelected : classes.iconRow}>
              <Link to="/operations" className={findLocation() == "/operations" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarOperations">
                <StyledTooltip
                  title={'Operations'}
                  placement="right"
                  arrow={true}
                >
                  <span className={findLocation() == "/operations" ? classes.selectedListItemSpan : classes.listItemSpan}>
                    Operations
                  </span>
                </StyledTooltip>
              </Link>
            </div>
            <List className={classes.subList}>
              {
                permissions.patching_report &&
                <Link to="/patching-report" className={findLocation() == "/patching-report" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarPatching">
                  <ListItem className={findLocation() == "/patching-report" ? classes.selectedSubListItem : classes.subListItem}>
                    <StyledTooltip
                      title={'Patching'}
                      placement="right"
                      arrow={true}
                    >
                      <span>Patching</span>
                    </StyledTooltip>
                  </ListItem>
                </Link>
              }
              {
                permissions.backups_report &&
                <Link to="/backups-report" className={findLocation() == "/backups-report" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarBackups">
                  <ListItem className={findLocation() == "/backups-report" ? classes.selectedSubListItem : classes.subListItem}>
                    <StyledTooltip
                      title={'Backups'}
                      placement="right"
                      arrow={true}
                    >
                      <span>Backups</span>
                    </StyledTooltip>
                  </ListItem>
                </Link>
              }
              {
                feature_flag_pulse_user_access_enabled && permissions.pulse_user_access_report &&
                <Link to="/pulse-user-permissions-report" className={findLocation() == "/pulse-user-permissions-report" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarPulseUserAccess">
                  <ListItem className={findLocation() == "/pulse-user-permissions-report" ? classes.selectedSubListItem : classes.subListItem}>
                    <StyledTooltip
                      title={'Pulse User Permissions'}
                      placement="right"
                      arrow={true}
                    >
                      <span>Pulse User Permissions</span>
                    </StyledTooltip>
                  </ListItem>
                </Link>
              }
              {
                (accountTypes.includes('AWS') && permissions.notifications) ?
                  <Link to="/operations-notifications" className={findLocation() == "/operations-notifications" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarOperationsNotifications">
                    <ListItem className={findLocation() == "/operations-notifications" ? classes.selectedSubListItem : classes.subListItem}>
                      <StyledTooltip
                        title={'Operations Notifications'}
                        placement="right"
                        arrow={true}
                      >
                        <span className={classes.notificationsItem}>
                          <span style={{ paddingRight: '5px' }}>Notifications</span>
                          <Notifications fontSize="small" />
                        </span>
                      </StyledTooltip>
                    </ListItem>
                  </Link> : null
              }
            </List>
          </ListItem>}
        {costDataAvailable &&
          <ListItem className={classes.listItem}>
            <div className={findLocation() == "/costs" ? classes.iconRowSelected : classes.iconRow}>
              <Link to="/costs" className={findLocation() == "/costs" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarCosts">
                <StyledTooltip
                  title={'Costs'}
                  placement="right"
                  arrow={true}
                >
                  <span className={findLocation() == "/costs" ? classes.selectedListItemSpan : classes.listItemSpan}>
                    Costs
                  </span>
                </StyledTooltip>
              </Link>
            </div>
            <List className={classes.subList}>
              <Link to="/cost-changes" className={findLocation() == "/cost-changes" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarCostChanges">
                <ListItem className={findLocation() == "/cost-changes" ? classes.selectedSubListItem : classes.subListItem} id="sidebarCostChangesWeek">
                  <StyledTooltip
                    title={'Week-Over-Week'}
                    placement="right"
                    arrow={true}
                  >
                    <span>Week-Over-Week</span>
                  </StyledTooltip>
                </ListItem>
              </Link>
              <Link to="/cost-changes-monthly" className={findLocation() == "/cost-changes-monthly" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarCostChanges">
                <ListItem className={findLocation() == "/cost-changes-monthly" ? classes.selectedSubListItem : classes.subListItem} id="sidebarCostChangesMonth">
                  <StyledTooltip
                    title={'Month-Over-Month'}
                    placement="right"
                    arrow={true}
                  >
                    <span>Month-Over-Month</span>
                  </StyledTooltip>
                </ListItem>
              </Link>
              <Link to="/cloudhealth" className={findLocation() == "/cloudhealth" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarCloudHealth">
                <ListItem className={findLocation() == "/cloudhealth" ? classes.selectedSubListItem : classes.subListItem} id="sidebarCostOptimization">
                  <StyledTooltip
                    title={'Cost Optimization'}
                    placement="right"
                    arrow={true}
                  >
                    <span>Cost Optimization</span>
                  </StyledTooltip>
                </ListItem>
              </Link>
              {
                permissions.notifications &&
                <Link to="/cost-notifications" className={findLocation() == "/cost-notifications" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarCostNotifications">
                  <ListItem className={findLocation() == "/cost-notifications" ? classes.selectedSubListItem : classes.subListItem} id="sidebarCostNotifications">
                    <StyledTooltip
                      title={'Cost Notifications'}
                      placement="right"
                      arrow={true}
                    >
                      <span className={classes.notificationsItem}>
                        <span style={{ paddingRight: '5px' }}>Notifications</span>
                        <Notifications fontSize="small" />
                      </span>
                    </StyledTooltip>
                  </ListItem>
                </Link>
              }
            </List>
          </ListItem>}
        <List className={classes.bottomList}>
          <ListItem className={classes.listItem}>
            <div className={classes.iconRow}>
              <Link to="/profile" className={classes.anchorTag}>
                <StyledTooltip
                  title={'Profile'}
                  placement="right"
                  arrow={true}
                >
                  <span className={classes.listItemSpan}>
                    Profile
                  </span>
                </StyledTooltip>
              </Link>
            </div>
            <List className={classes.subList}>
              <Link to="/profile" className={findLocation() == "/profile" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarProfile">
                <ListItem className={findLocation() == "/profile" ? classes.selectedSubListItem : classes.subListItem}>
                  <StyledTooltip
                    title={'Profile'}
                    placement="right"
                    arrow={true}
                  >
                    <span>Account</span>
                  </StyledTooltip>
                </ListItem>
              </Link>
              {
                permissions.notifications &&
                <Link to="/notifications" className={findLocation() == "/notifications" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarNotifications">
                  <ListItem className={findLocation() == "/notifications" ? classes.selectedSubListItem : classes.subListItem}>
                    <StyledTooltip
                      title={'Notifications'}
                      placement="right"
                      arrow={true}
                    >
                      <span className={classes.notificationsItem}>
                        <span style={{ paddingRight: '5px' }}>Notifications</span>
                        <Notifications fontSize="small" />
                      </span>
                    </StyledTooltip>
                  </ListItem>
                </Link>
              }
            </List>
          </ListItem>
          <ListItem className={classes.listItem}>
            <div className={findLocation() == "/support" ? classes.iconRowSelected : classes.iconRow}>
              <Link to="/support" className={findLocation() == "/support" ? classes.selectedAnchorTag : classes.anchorTag} id="sidebarSupport">
                <StyledTooltip
                  title={'Support'}
                  placement="right"
                  arrow={true}
                >
                  <span className={findLocation() == "/support" ? classes.selectedListItemSpan : classes.listItemSpan}>
                    Support
                  </span>
                </StyledTooltip>
              </Link>
            </div>
            <List className={classes.subList}>
              <a target="_blank" href={"https://portal.rapidscale.net/auth/loggedin/lw/"} className={classes.anchorTag} id="linkToRapidscale">
                <ListItem className={classes.subListItem}>
                  <StyledTooltip
                    title={'Go to Rapidscale Portal'}
                    placement="right"
                    arrow={true}
                  >
                    <span>Rapidscale Portal</span>
                  </StyledTooltip>
                </ListItem>
              </a>
            </List>
          </ListItem>
        </List>

      </List>
    </Drawer>
  )
}

export default withRouter(Sidebar);
