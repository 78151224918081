async function inspectSessionValidity(res) {
    if( res.status == 401 || res.status == 403 ){
        window.location.href = `${window.location.origin}/accounts/login`;
    }
    console.log(res)
}

async function inspectAndFetch(path, conf) {
   const res = await fetch(path, conf)
   inspectSessionValidity(res)
   return res
}

export {
    inspectSessionValidity, inspectAndFetch
}
