import * as React from 'react';
import { Grid } from '@material-ui/core';

import styles from '../DefaultContainerStyles';

const FailurePage = () => {
  const classes = styles();

  return (
    <Grid container xs={12} style={{ justifyContent: 'center' }}>
      <div className={classes.errorContainer}>
        500 Error | Application currently not available
      </div>
    </Grid>
  )
}

export default FailurePage;
