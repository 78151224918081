import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const pulseUserAccessStyles = makeStyles((theme: Theme) =>
  createStyles({
    whiteBackground: {
        backgroundColor: '#ffffff'
    },
    greyBackground: {
        backgroundColor: '#eee'
    },
    mainContainer: {
        width: '97%',
        height: '95%',
        margin: '0 20px 20px 20px'
    },
    downloadButtonGrid: {
        margin: '12px',
    },
    footerTextGrid: {
        margin: '12px',
    },
    h4Header: {
        color: '#757575',
        fontSize: '18px',
        fontFamily: 'Lato',
        fontStyle: 'italic',
        fontWeight: 500,
        lineHeight: '1.5rem',
        margin: '0'
    },
    tableContainer: {
        width: '100%',
        overflowX: 'auto',
        height: '86%',
    },
    headerContainer: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        width: 'fit-content',
    },
    bodyContainer: {
        width: 'fit-content',
        height: '100%',
    },
    tableBodyContainer: {
        width: '100%',
        height: '100%',
    },
    tableHeaderCell: {
        backgroundColor: '#013F66',
        color: 'white',
        fontSize: '16px',
        minWidth: '155px',
        maxWidth: '155px',
        wordWrap: 'break-word',
        textAlign: 'center',
        fontFamily: 'Lato'
    },
    tableBodyCell: {
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Lato',
        minWidth: '155px',
        maxWidth: '155px',
        wordWrap: 'break-word'
    },
    wideCell: {
        minWidth: '215px',
        maxWidth: '215px',
    },
    arrayCell: {
        textAlign: 'center',
        marginBottom: '10px'
    },
    cellLink: {
        textAlign: 'left',
        color: '#2B7BC7'
    },
    checkbox: {
        marginBottom: '20px',
        color: 'primary'
    },
    startIcon: {
        margin: '0 0 0 10px'
    },
})
);

export const inlineStyles = {
    accountInfoTooltip: {
        fontSize: '14px',
        padding: '10px'
    } as React.CSSProperties,
};

export default pulseUserAccessStyles;
