import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import containerStyles  from '../containers/DefaultContainerStyles';
import { baseUserInformation } from "../constants/links";
import { getRequestConfig } from '../lib/auth'
import AppContainer from '../AppContainer';
import FailurePage from '../containers/error-pages/500';
import { setBaseUserInformation } from '../actions'


declare global {
  interface Window {
    IS_CI_CD: string
    dataLayer: any[]
  }
}


const Authentication = () => {
  const dispatch = useDispatch();
  const [displayApp, setDisplayApp] = useState(false);
  const [error, setError] = useState(false);
  const classes = containerStyles();

  const setUserInfo = () => {
    const config = getRequestConfig();
    fetch(`${ window.location.origin}/${baseUserInformation}`, config)
    .then(response => {
      if (response.status == 401 || response.status == 403) {
        window.location.href = `${window.location.origin}/accounts/login`;
        return Promise.resolve()
      }
      else {
        return response.json();
      }
    })
    .then(data => {
      if (data) {
        window.is_impersonating = data['impersonation'];
        window.single_permission_url = data['single_permission_url'];
        window.waffle = data['waffle'];
        dispatch(setBaseUserInformation(
          {
            client_id: data['okta_admin_config_client_id'],
            client_url: data['admin_config_endpoint'],
            oidc_issuer: data['okta_admin_config_issuer']
          }
        ));

        window.dataLayer = window.dataLayer || [];
        window.gtag = function() {window.dataLayer.push(arguments);}
        gtag('js', new Date());

        // set up google analytics
        if (window.IS_CI_CD == 'TRUE') {
          gtag('config', data['google_analytics_id'] || '', {
            send_page_view: false,
            'user_id': data['user_id'] || ''
          })
          gtag('set', 'user_properties', {
            client_name: data['account_name']
          })
        }

        setDisplayApp(true)
      }
    })
    .catch(err => {
      console.log(err);
      setError(true);
    })
  }

  if (error) {
    return <FailurePage />
  }

  if (displayApp) {
    return <AppContainer />
  }
  else {
    setUserInfo();
    return (
      <Grid container xs={12} className={classes.loadingContainer}>
        <CircularProgress size={80} color="inherit" />
      </Grid>
    )
  }
}

export default Authentication;


