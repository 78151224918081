import * as React from 'react';
import UnauthorizedPage from '../containers/error-pages/403';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import containerStyles from '../containers/DefaultContainerStyles';

interface ProtectedRouteProps {
  component: React.ComponentType
  authorized: boolean
  isLoading: boolean
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const Component: React.ComponentType = props.component;

  const classes = containerStyles();

  if (props.isLoading) {
    return (
      <Grid container sm={12} className={classes.loadingContainer}>
        <CircularProgress size={60} color="inherit" />
      </Grid>
    )
  }

  if (!props.authorized) {
    return (
      <UnauthorizedPage />
    )
  }

  return (
    <Component/>
  )
}

export default ProtectedRoute;
