export const oktaUserManagementLink = "https://logicworks.okta.com/enduser/settings";
export const deliveryChannelInformation =  "api/users/delivery-channel-information/";
export const baseUserInformation = "api/users/base-information/";
export const dlpEnabledCloudAccounts = "/api/dlp-enabled-cloud-accounts/";
export const costTrendChart = '/api/powerbi-token/cost-chart/';
export const costTrendByProviderChart = '/api/powerbi-token/cost-chart-by-provider/';
export const costTrendByProviderChartHome = '/api/powerbi-token/cost-chart-by-provider-home/';
export const costTrendAwsOnly = '/api/powerbi-token/cost-chart-aws-only/';
export const costTrendAzureOnly = '/api/powerbi-token/cost-chart-azure-only/';
export const costTrendAwsOnlyHome = '/api/powerbi-token/cost-chart-aws-only-home/';
export const costTrendAzureOnlyHome = '/api/powerbi-token/cost-chart-azure-only-home/';
export const backupsPiechart = '/api/powerbi-token/backups-piechart/';
export const patchingPiechart = '/api/powerbi-token/patching-piechart/';
export const ec2EnvironmentsChart = '/api/powerbi-token/ec2-environments/';
export const ec2RegionsChart = '/api/powerbi-token/ec2-regions/';
export const ec2VpcChart = '/api/powerbi-token/ec2-vpcs/';
export const consoleLoginMap = '/api/powerbi-token/console-login-map/';
export const operationPatchingPieChart = '/api/powerbi-token/operation-patching/';
export const backupsPatchingPieChart = '/api/powerbi-token/operation-backups/';
export const patchingSeverityTable = '/api/powerbi-token/operation-patching-severity/';
export const patchingFilters = '/api/powerbi-token/patching-filters/';
export const ec2RegionPie = '/api/powerbi-token/ec2-regions-pie-chart/';
export const ec2VPCPie = '/api/powerbi-token/ec2-vpc-pie-chart/';
export const threatStackUrl = 'https://logicworks.okta.com/home/logicworks_threatstack_1/0oaeoclcpijXp1D5k1t7/alneocr29hIpTNbzZ1t7';
export const logicworks_logo = "/dist/assets/Logicworks_White_Logo.png"
export const logicworks_cube_logo = "/dist/assets/Logicworks_Cube_Logo.png"
export const pulse_logo = "/dist/assets/logicworks_pulse_logo.svg"
export const pulse_text = "/dist/assets/pulse_text.svg"
export const bell_icon = "/dist/assets/pulse_main_screen_notification_icon_100x100.png"
export const alarm_icon = "/dist/assets/pulse_main_screen_alarms_icon_100x100.png"
export const cost_icon = "/dist/assets/pulse_main_screen_cost_icon_100x100.png"
export const security_icon = "/dist/assets/pulse_main_screen_security_icon_100x100.png"
export const support_ticket_icon = "/dist/assets/pulse_main_screen_support_icon_100x100.png"
export const cloudhealth_logo = "/dist/assets/cloudhealth_gradient.png"
export const alertlogic_logo = "/dist/assets/alertlogic_logo.png"
export const trendmicro_logo = "/dist/assets/trendmicro_logo.jpg"
export const pulse_arrow_25x25 = "/dist/assets/Pulse_arrow_icon_25x25.png"
export const pulse_arrow_svg = "/dist/assets/Pulse_arrow_icon.svg"
export const threatstack_logo = "/dist/assets/threatstack_logo.jpg"
export const rapidscale_banner = "/dist/assets/RapidScale_Banner.svg"
