import {
  GET_USER_CLOUD_ACCOUNTS
} from '../actions/accounts/types';


export interface AccountsState {
  cloudAccounts: any[]
}

export const initialState = {
  cloudAccounts: []
}

function setUserCloudAccounts(prevState, data) {
  return { ...prevState, cloudAccounts: data }
}


export default function (state: AccountsState = initialState, action) {
  switch (action.type) {
    case GET_USER_CLOUD_ACCOUNTS:
      return setUserCloudAccounts(state, action.payload);
    default:
      return state;
  }
}
