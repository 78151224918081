import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';

const drawerWidth: number = 240;
const containerStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingContainer: {
      display: 'flex',
      minHeight: '600px',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#002741'
    },
    errorContainer: {
      display: 'flex',
      minHeight: '600px',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#002741',
      fontSize: '3vw'
    },
    deprecationContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '600px',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: '80px',
      paddingRight: '80px',
      color: '#002741',
      fontSize: '3vw'
    },
    deprecationContainerLink: {
      textDecoration: 'underline',
      color: '#002741',
      cursor: 'pointer',
      paddingLeft: '12px'
    },
    smallLoadingContainer: {
      display: 'flex',
      minHeight: '400px',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#002741'
    },
    adminControlsHeader: {
      color: '#003e67',
      fontSize: '24px',
      marginLeft: '40px',
      marginTop: '40px',
      marginBottom: '0px'
    },
    defaultHeader: {
      color: '#111',
      fontSize: '32px',
      marginBottom: 0
    },
    thirdPartyTitle: {
      color: "#003e67",
      fontSize: '32px',
      marginBottom: 0,
      marginTop: 0
    },
    headerDivider: {
      height: '5px',
      backgroundColor: '#111',
      color: '#111',
      marginTop: "20px",
      marginBottom: '25px'
    },
    thirdPartyHeaderDivider: {
      height: '5px',
      backgroundColor: '#f3f3f3',
      color: '#f3f3f3',
      marginTop: "10px",
      marginBottom: '25px',
      marginLeft: '30px',
      marginRight: '30px'
    },
    notificationsHeaderDivider: {
      height: '4px',
      backgroundColor: '#e5e5e5',
      color: '#e5e5e5'
    },
    notificationsDivider: {
      height: '5px',
      backgroundColor: '#e5e5e5',
      color: '#e5e5e5',
      marginTop: "20px",
      marginBottom: '25px'
    },
    notificationsSectionsContainer: {
      paddingLeft: '20px',
      marginLeft: '20px'
    },
    ssoContainer: {
      marginTop: '60px',
      scrollbarWidth: 'thin'
    },
    ssoContainerScroll: {
      marginTop: '60px',
      scrollbarWidth: 'thin'
    },
    ssoBody: {
      paddingLeft: '30px',
      paddingRight: '30px',
      marginBottom: '35px'
    },
    ssoIndent: {
      marginLeft: '10px'
    },
    ssoButton: {
      marginLeft: '60px',
      color: 'white',
      fontSize: '18px',
      padding: '10px 20px',
      fontWeight: 400,
      backgroundColor: '#0D70F7',
      borderRadius: 0
    },
    ssoActivationLink: {
      marginLeft: '60px',
      fontSize: '18px',
      fontWeight: 400,
      textDecoration: 'none',
      color: '#2B7BC7',
      cursor: 'pointer'
    },
    ssoAnchorTag: {
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    defualtDivider: {
      marginTop: '25px',
      marginBottom: '25px'
    },
    thirdPartyHeader: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '20px',
      paddingLeft: '30px',
      paddingRight: '30px',
      color: "#003e67",
      backgroundColor: 'white'
    },
    thirdPartyLogoImage: {
      marginLeft: 'auto',
      width: '20%'
    },
    costChangeHeaderTitle: {
      backgroundColor: '#F3F3F3',
      height: '80px',
      width: '100%'
    },
    costChangeTitleText: {
      color: '#044269',
      display: 'flex',
      alignItems: 'center',
      paddingTop: '20px',
      paddingLeft: '25px',
      fontSize: '26px'
    }
  })
);


export default containerStyles;
