import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer, { AppState } from '../reducers';
import homeReducer, {initialState as initialHomeState } from '../reducers/home-reducer';
import dlpReducer, { initialState as initialDLPState } from '../reducers/dlp-reducer';
import accountsReducer, { initialState as initialAccountsState } from '../reducers/accounts-reducer';
import notificationsReducer, { initialState as initialNotificationsState } from '../reducers/notifications-reducer';
import reportingReducer, { initialState as initialReportingState } from '../reducers/reporting-reducer';

const middleware = [thunk];
const enchancers = [];
const windowIfDefined = typeof window === 'undefined' ? null : window as any;
const composeEnhancers = windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const initialState: AppState = { 
  home: initialHomeState, 
  dlp: initialDLPState, 
  notifications: initialNotificationsState,
  accounts: initialAccountsState,
  reporting: initialReportingState
};

const store = createStore<AppState>(
  rootReducer,
  initialState as any,
  composeEnhancers(applyMiddleware(...middleware), ...enchancers)
)

export default store;
