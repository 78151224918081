import { Tooltip, withStyles } from '@material-ui/core';

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: '#111',
    padding: '10px 20px',
    fontFamily: 'Lato',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    fontSize: 13,
  },
  arrow: {
    color: 'white'
  }
}))(Tooltip);

export default StyledTooltip;
