import * as React from 'react';
import { Button } from '@material-ui/core';
import { useSelector, RootStateOrAny } from "react-redux";
import impersonationHeaderStyles from './ImpersonationHeaderStyles';
import { inspectAndFetch } from '../utilities/api';

const ImpersonationHeader = (props) => {
  const classes = impersonationHeaderStyles();
  const user = useSelector((state: RootStateOrAny) => state.home.user);

  const handleUnsetImpersonationInfo = async () => {
    const config: RequestInit = { headers: { 'X-Requested-With': 'XMLHttpRequest' }, credentials: 'include' };
    await inspectAndFetch(`/impersonate/stop/`, config);
    window.location.pathname = '/'
  }

  return (
    <div className={classes.impersonationHeaderContainer}>
      <div className={classes.impersonationHeaderText}>
        Currently viewing Pulse in impersonation mode. This mode is authorized for Logicworks employees only. All options are read-only and actions are logged. Current view: {user.full_name}
      </div>
      <div className={classes.impersonationHeaderButton}>
        <Button 
          onClick={(e) => handleUnsetImpersonationInfo()}
          variant="contained"
          color="secondary"
        >
          Exit Impersonation
        </Button>
      </div>
    </div>
  )
}

export default ImpersonationHeader;
