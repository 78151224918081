import {
  GET_NOTIFICATIONS, GET_NOTIFICATION_DETAILS, GET_NOTIFICATION_LAST_SCANS
} from '../actions/types';
import { CloudAccountNotifictions, NotificationDetail, SelectedValues, Prefs  } from '../types';

export interface NotificationsState {
  prefs: Prefs
  lastScans: Object
  selectedValues: SelectedValues
  environments: string[]
  email: string
  phone: string
  subscriberStatus: string
  notificationDetails: NotificationDetail[]
}

function setNotifications(prevState, data) {
  let { prefs, initial, envs, 
    email, phone, state } = data;


  return {
    ...prevState, prefs, selectedValues: initial,
    environments: envs, email, phone, subscriberStatus: state
  }
}

function setNotificationDetails(prevState, data) {
  let { notification_details } = data;
  return { ...prevState, notificationDetails: notification_details };
}

function setNotificationLastScans(prevState, data) {
  const { last_scans } = data;

  return { ...prevState, lastScans: last_scans };
}

export const initialState: NotificationsState = {
  prefs: {},
  lastScans: {},
  selectedValues: {},
  environments: [],
  email: '',
  phone: '',
  subscriberStatus: '',
  notificationDetails: []
};

export default function (state: NotificationsState = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return setNotifications(state, action.payload);
    case GET_NOTIFICATION_DETAILS:
      return setNotificationDetails(state, action.payload);
    case GET_NOTIFICATION_LAST_SCANS:
      return setNotificationLastScans(state, action.payload)
    default:
      return state;
  }
}