import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const pulseUserAccessReportingStyles = makeStyles((theme: Theme) =>
  createStyles({
    pulseUserAccessReportingContainer: {
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
    },
    pulseUserAccessReportingBodyHeader: {
        width: "100%",
        height: "80px",
        display: "flex",
        alignItems: "center",
        background: "#f3f3f3",
        paddingLeft: "25px",
        paddingTop: "12px",
        fontSize: "26px",
        color: "#003e67",
        fontWeight: 700,
        position: 'sticky',
        top: '0',
        zIndex: 1,
    },
    pulseUserAccessReportingBody: {
        height: 'calc(100% - 80px)',
        width: '100%',
        overflowX: 'auto',
        position: 'relative'
    }
  })
);

export default pulseUserAccessReportingStyles;
