const headersMapping = {
    name: 'Name',
    email: 'Email',
    perm_pulse_enabled: 'Pulse Enabled',
    customer_organizations: 'Customer Organization',
    perm_servicedesk: 'Service Desk',
    perm_notifications: 'Pulse Notification',
    service_notifications_access: 'Service Notification',
    perm_cloudhealth: 'CloudHealth',
    perm_alertlogic: 'Alert Logic',
    perm_trendmicro: 'Trend Micro Role',
    perm_threatstack: 'Threat Stack',
    perm_dataloss_prevention: 'Data Loss Prevention Role',
    last_login_time: 'Last Login'
};

export default headersMapping;