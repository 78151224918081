import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ContractContact } from "../../types";
import pulseUserAccessStyles, { inlineStyles } from "./PulseUserAccessStyles";
import { format } from 'date-fns';
import headersMapping from './PulseUserAccessMapping';
import tooltipMapping from './PulseUserPermissionsTooltipMapping';
import { getRequestConfig } from "../../lib/auth";
import UnauthorizedPage from "../../containers/error-pages/403";
import CircularProgress from '@material-ui/core/CircularProgress';
import containerStyles from '../../containers/DefaultContainerStyles';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';



const PulseUserAccess = () => {
    const classes = pulseUserAccessStyles();
    const defaultClasses = containerStyles();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [authorized, setAuthorized] = useState<boolean>(false);
    const [usersData, setUsersData] = useState<ContractContact[]>();
    const [tableHeaders, setTableHeaders] = useState<string[]>();
    const [expanded, setExpanded] = useState(false);
    const dateTimeFormat: string = 'M/d/yyyy h:mma';
    const lastUpdatedText = 'The data will be refreshed every 30 min in the backend, please refresh the report to see the latest data.';
    const bottomNoteText = 'If you have any question, please submit a ticket via Jira service desk or reach out to your service delivery manager.';
    const csvDelimiter = ',';
    
    useEffect(() => {
        async function fetchData() {
            const apiUrl = '/api/pulse-user-permissions-report/';
            const config = getRequestConfig();
            await fetch(apiUrl, config).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 403) {
                    // Handle anuthorized
                    setAuthorized(false);
                    throw new Error(`API ${response.url} failed: ${response.statusText}`);
                } else {
                    // Handle other error codes
                    setAuthorized(true);
                    throw new Error(`API ${response.url} failed: ${response.statusText}`);
                }
            })
            .then(userData => {
                if (userData && userData.length > 0) {
                    const tableHeadersArray: string[] = Object.keys(userData[0]).map(header => headersMapping[header] || header);
                    setTableHeaders(tableHeadersArray);
                    setUsersData(userData);
                } else {
                    console.error('No data found for the user');
                }
                setAuthorized(true);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
            });
        }

        fetchData();
    }, []);

    const convertStringWithComma = (val: string) => {
        if (val.includes(',')) {
            return `"${val}"`
        }
        return val;
    }

    const handleDownloadReport = () => {
        const headers = tableHeaders || [];
        const data = usersData || [];

        const csvContent = `${headers.join(csvDelimiter)}\n${data
            .map((row) =>
                Object.entries(row)
                    .map(([key, value]) => {
                        if (Array.isArray(value)) {
                            const nonEmptyValues = value.filter((val) => val !== '');
                            if (nonEmptyValues.length === 0) {
                                return '';
                            }
                            const firstVal = nonEmptyValues[0];
    
                            // if the first value is boolean and is true, concatenate the rest of the values after 'TRUE:'
                            // no concatenation is needed if false, strings are concatenated with '|'
                            if (typeof firstVal === 'boolean') {
                                if (firstVal && nonEmptyValues.length > 1) {
                                    return `${String(firstVal).toUpperCase()}: ${nonEmptyValues
                                        .slice(1)
                                        .map(val => convertStringWithComma(val))
                                        .join(' | ')}`;
                                }
                                return String(firstVal).toUpperCase();
                            } else {
                                return nonEmptyValues
                                    .map(val => convertStringWithComma(val))
                                    .join(' | ');
                            }
                        } else if (typeof value === 'string') {
                            return convertStringWithComma(value);
                        } else {
                            return value;
                        }
                    })
                    .join(csvDelimiter)
            )
            .join('\n')}`;

        // create blob object with CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // create temporary URL for the Blob object
        const url = URL.createObjectURL(blob);

        // create temp <a> element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Pulse-User-Permissions-Report.csv';

        // click the link to download csv
        link.click();

        // remove temporary URL and <a> element
        URL.revokeObjectURL(url);
        link.remove();
    };

    
    const renderTooltip = (header: string) => { 
        return header !== 'Name' && header !== 'Email' ? (
            <Tooltip componentsProps={{ tooltip: { sx: { ...inlineStyles.accountInfoTooltip}}}}
                title={<span>{tooltipMapping[header] || ''}</span>}
                >
                <InfoIcon sx={{ color: 'white', fontSize: '1rem', marginLeft: '0.5rem'}}/>
            </Tooltip>
        ) : null
    }

    const renderHeaders = () => {
        return tableHeaders && (
            <>
                {tableHeaders.map((header, idx) => (
                    <TableCell key={idx} className={header.length < 20 ? classes.tableHeaderCell: `${classes.tableHeaderCell} ${classes.wideCell}`} id={`tbl-head-cell-${idx}`}>
                        {header}
                        {renderTooltip(header)}
                    </TableCell>
                ))}
            </>
        )
    }

    const renderOrganizations = (key: string, data: string[], rowIdx: number, idx: number) => {
        // if there are up to 3 array records
        if (data.length < 4) {
            return (
                <TableCell key={rowIdx+'-'+idx} className={key.length < 20 ? classes.tableBodyCell : `${classes.tableBodyCell} ${classes.wideCell}`} id={"tbl-body-cell-"+rowIdx+'-'+idx}>
                    {
                        data.map((val, idx) => {
                            return <div key={idx} className={classes.arrayCell}>{val}</div>
                        })
                    }
                </TableCell>
            )
        } // display all array records
        else if (expanded) {
            return (
                <TableCell key={rowIdx+'-'+idx} className={key.length < 20 ? classes.tableBodyCell : `${classes.tableBodyCell} ${classes.wideCell}`} id={"tbl-body-cell-"+rowIdx+'-'+idx}>
                    {
                        data.map((val, idx)  => {
                            return <div key={idx} className={classes.arrayCell}>{val}</div>
                        })
                    }
                    <a href="#" onClick={() => setExpanded(false)} className={classes.cellLink}>- less</a>
                </TableCell>
            )

        } // by default return up to 3 array records and let expand
        else {
            return (
                <TableCell key={rowIdx+'-'+idx} className={key.length < 20 ? classes.tableBodyCell : `${classes.tableBodyCell} ${classes.wideCell}`} id={"tbl-body-cell-"+rowIdx+'-'+idx}>
                    {
                        data.slice(0, 3).map((val, idx)  => {
                            return <div key={idx} className={classes.arrayCell}>{val}</div>
                        })
                    }
                    <a href="#" onClick={() => setExpanded(true)} className={classes.cellLink}>+ more</a>
                </TableCell>
            )
        }
    }

    const renderRoles = (key: string, data: any[], rowIdx: number, idx: number) => {
        return data[0] ? 
            <TableCell key={idx} className={key.length < 20 ? classes.tableBodyCell : `${classes.tableBodyCell} ${classes.wideCell}`} id={"tbl-body-cell-"+rowIdx+'-'+idx}>
                <Checkbox disabled checked={data[0]} color="primary"/><br />
                {data[1]}
            </TableCell> :
            <TableCell key={idx} className={key.length < 20 ? classes.tableBodyCell : `${classes.tableBodyCell} ${classes.wideCell}`} id={"tbl-body-cell-"+rowIdx+'-'+idx}></TableCell>
    }

    const renderRowCells = (row: ContractContact, rowIdx: number) => {
        return (
            Object.entries(row).map(([key, value], idx) => {
                if (value instanceof Date) {
                    let dateString = '';
                    if (value) {
                        dateString = format(value, dateTimeFormat);
                    }
                    return <TableCell key={rowIdx+'-'+idx} className={key.length < 20 ? classes.tableBodyCell : `${classes.tableBodyCell} ${classes.wideCell}`} id={"tbl-body-cell-"+rowIdx+'-'+idx}>{dateString}</TableCell>
                } else if (Array.isArray(value)) {
                    if (key === 'customer_organizations' && value.length > 0) {
                        return renderOrganizations(key, value, rowIdx, idx)
                    } else if (key === 'perm_trendmicro' || key === 'perm_dataloss_prevention') {
                        return renderRoles(key, value, rowIdx, idx)
                    }
                } else if (typeof value === 'boolean') {
                    return value ? 
                        <TableCell key={rowIdx+'-'+idx} className={key.length < 20 ? classes.tableBodyCell : `${classes.tableBodyCell} ${classes.wideCell}`} id={"tbl-body-cell-"+rowIdx+'-'+idx}>
                            <Checkbox disabled checked={value} className={classes.checkbox} />
                        </TableCell> :
                        <TableCell key={rowIdx+'-'+idx} className={key.length < 20 ? classes.tableBodyCell : `${classes.tableBodyCell} ${classes.wideCell}`} id={"tbl-body-cell-"+rowIdx+'-'+idx}></TableCell>
                } else {
                    return <TableCell key={rowIdx+'-'+idx} className={key.length < 20 ? classes.tableBodyCell : `${classes.tableBodyCell} ${classes.wideCell}`} id={"tbl-body-cell-"+rowIdx+'-'+idx}>{value.replace(/,*$/, '')}</TableCell>
                }
            })
        );
    }

    const renderRows = () => {
        return usersData && usersData.map((row, idx) => {
            return (
                <TableRow key={idx} className={idx%2 === 0 ? classes.whiteBackground : classes.greyBackground } id="body-tbl-row">
                    {renderRowCells(row, idx)}
                </TableRow>
            )
        });
    }

    return (
        isLoading ?
            <Grid container sm={12} className={defaultClasses.loadingContainer}>
                <CircularProgress size={60} color="inherit" />
            </Grid> :
        authorized ? 
            <Grid container id="container" className={classes.mainContainer}>
                <Grid item xs={12} className={classes.downloadButtonGrid}>
                    <Button id="download-button" variant="contained" color="primary"
                        startIcon={<FileDownloadIcon className={classes.startIcon} />}
                        onClick={handleDownloadReport} 
                    />
                </Grid>
                <div className={classes.tableContainer}>
                    {/* table headers */}
                    <div className={classes.headerContainer}>
                        <TableContainer component={Paper}>
                            <Table id="tbl">
                                <TableHead id="tbl-head">
                                    <TableRow id="head-tbl-row">
                                        {renderHeaders()}
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                    </div>
                    {/* table rows */}
                    <div className={classes.bodyContainer}>
                        <TableContainer component={Paper} className={classes.tableBodyContainer}>
                            <Table id="tbl">
                                <TableBody id="tbl-body">
                                    {renderRows()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <Grid item xs={12} className={classes.footerTextGrid}>
                    <h4 className={classes.h4Header} id="last-updated-header">
                        {lastUpdatedText}
                        <br/>
                        {bottomNoteText}
                    </h4>
                </Grid>
            </Grid> :
            <UnauthorizedPage />
    );
}

export default PulseUserAccess;