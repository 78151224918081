export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const UPDATE_TIMESTAMP = 'UPDATE_TIMESTAMP';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';
export const SET_IMPERSONATION_INFO = 'SET_IMPERSONATION_INFO';
export const UNSET_IMPERSONATION_INFO = 'UNSET_IMPERSONATION_INFO';
export const SET_COST_DATA_AVAILABILITY = 'SET_COST_DATA_AVAILABILITY';

export const GET_CLOUD_ACCOUNT_INFO = 'GET_CLOUD_ACCOUNT_INFO';
export const UPDATE_CLOUD_ACCOUNT_INFO = 'UPDATE_CLOUD_ACCOUNT_INFO';
export const FAILED_CLOUD_ACCOUNT_UPDATE = 'FAILED_CLOUD_ACCOUNT_UPDATE';
export const CLEAR_CLOUD_ACCOUNT_UPDATE = 'CLEAR_CLOUD_ACCOUNT_UPDATE';
export const OPEN_DLP_MODAL = 'OPEN_DLP_MODAL';
export const CLOSE_DLP_MODAL = 'CLOSE_DLP_MODAL';
export const SET_DLP_DATA_LOADING = 'SET_DLP_DATA_LOADING';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATION_DETAILS = 'GET_NOTIFICATION_DETAILS';
export const GET_NOTIFICATION_LAST_SCANS = 'GET_NOTIFICATION_LAST_SCANS';

export const SET_EARLIEST_COST_DATE = 'SET_EARLIEST_COST_DATE';

export const SET_BASE_USER_INFORMATION = 'SET_BASE_USER_INFORMATION';
export const SET_OKTA_ID = 'SET_OKTA_ID';
