import { combineReducers } from 'redux';
import homeReducer, { HomeState } from './home-reducer';
import dlpReducer, { DLPState } from './dlp-reducer';
import notificationsReducer, { NotificationsState } from './notifications-reducer';
import accountsReducer, { AccountsState } from './accounts-reducer';
import userReducers from './user-reducers';
import landingPageReducer from './landing-page-reducer';
import reportingReducer, { ReportingState } from './reporting-reducer';

export interface AppState {
  home: HomeState
  dlp: DLPState
  notifications: NotificationsState
  accounts: AccountsState
  reporting: ReportingState
}


const rootReducer = combineReducers<AppState>({
  home: homeReducer,
  dlp: dlpReducer,
  notifications: notificationsReducer,
  user: userReducers,
  accounts: accountsReducer,
  landingPage: landingPageReducer,
  reporting: reportingReducer
})

export default rootReducer;
