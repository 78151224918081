import {
  SET_PATCHING_FILTERS
} from './types';

export const setPatchingFilters = (filters) => {
  return {
    type: SET_PATCHING_FILTERS,
    payload: filters
  }
}
