import {
    GET_DELIVERY_CHANNEL_INFO,
    GET_USER_INFO
} from '../actions/user/types';
import { CloudAccountNotifictions, NotificationDetail } from '../types';


export interface userState {
    userInfo: {},
    deliveryChannelInfo: {}
}


function setUserInfo(prevState, data) {
    return { ...prevState, userInfo: data }
}

function setDeliveryChannelInfo(prevState, data) {
    return { ...prevState, deliveryChannelInfo: data }

}

export const initialState: userState = {
    userInfo: {
        first_name: "",
        last_name: "",
        email: "",
        full_name: "",
        account_name: "",
        phone: ""
    },
    deliveryChannelInfo: {
        email: "",
        phone: "",
        state: false,
        sfcontactId: ""
    }
};

export default function (state: userState = initialState, action) {
    switch (action.type) {
        case GET_USER_INFO:
            return setUserInfo(state, action.payload);
        case GET_DELIVERY_CHANNEL_INFO:
            return setDeliveryChannelInfo(state, action.payload);
        default:
            return state;
    }
}
