import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const impersonationHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    impersonationHeaderContainer: {
      display: 'flex',
      height: '60px',
      backgroundColor: '#ff7f21',
      color: 'white',
      zIndex: 1200,
      marginLeft: 0,
      position: 'relative',
      fontSize: '18px',
      padding: '8px 16px'
    },
    impersonationHeaderText: {
      width: '80%',
      fontFamily: 'Noto Sans, Roboto, sans-serif'
    },
    impersonationHeaderButton: {
      width: '20%',
      textAlign: 'center',
      marginTop: '10px'
    }
  }),
);

export default impersonationHeaderStyles;
