import {
    GET_MAP_REPORT_METRICS,
    GET_BACKUPS_REPORT_METRICS,
    GET_PATCHING_REPORT_METRICS,
    GET_EC2_REGIONS_REPORT_METRICS,
    GET_EC2_VPC_REPORT_METRICS,
} from '../actions/landingPage/types';


export interface LandingPageState {
    mapMetrics: {
        awsConsoleLoginCount: Number | null,
        awsActiveRegionsCount: Number | null,
        azureActiveRegionsCount: Number | null,
        isLoading: boolean
    },
    backUpMetrics: {
        totalNumberOfManagedInstance: Number | null,
        totalNumberOfBackUpInstances: Number | null,
        totalNumberOfInstances: Number | null,
        isLoading: boolean
    },
    patchingMetrics:{
        totalNumberOfPatchedInstances: Number | null,
        totalNumberOfInstances: Number | null,
        isLoading: boolean
    },
    ec2RegionsMetrics: {
        totalNumberOfInstances: Number | null,
        isLoading: boolean
    },
    ec2VpcMetrics: {
        totalNumberOfInstances: Number | null,
        isLoading: boolean
    }

}

export const initialState = {
    mapMetrics: {
        awsConsoleLoginCount: null,
        awsActiveRegionsCount: null,
        azureActiveRegionsCount: null,
        isLoading: true
    },
    backUpMetrics: {
        totalNumberOfManagedInstance: null,
        totalNumberOfBackUpInstances: null,
        totalNumberOfInstances: null,
        isLoading: true
    },
    patchingMetrics:{
        totalNumberOfPatchedInstances: null,
        totalNumberOfInstances: null,
        isLoading: true
    },
    ec2RegionsMetrics: {
        totalNumberOfInstances: null,
        isLoading: true
    },
    ec2VpcMetrics: {
        totalNumberOfInstances: null,
        isLoading: true
    }
}

function setMapMetrics(prevState, data) {
    return {...prevState, mapMetrics: {...data, isLoading: false}}
}
function setBackUpMetrics(prevState, data) {
    return {...prevState, backUpMetrics: {...data, isLoading: false}}
}

function setPatchingMetrics(prevState, data) {
    return {...prevState, patchingMetrics: {...data, isLoading: false}}
}

function setEc2VPC(prevState, data) {
    return {...prevState, ec2VpcMetrics: {...data, isLoading: false}}
}

function setEc2Regions(prevState, data) {
    return {...prevState, ec2RegionsMetrics: {...data, isLoading: false}}
}

export default function (state: LandingPageState = initialState, action) {
    switch (action.type) {
        case GET_MAP_REPORT_METRICS:
            return setMapMetrics(state, action.payload);
        case GET_BACKUPS_REPORT_METRICS:
            return setBackUpMetrics(state, action.payload);
        case GET_PATCHING_REPORT_METRICS:
            return setPatchingMetrics(state, action.payload);
        case GET_EC2_REGIONS_REPORT_METRICS:
            return setEc2Regions(state, action.payload);
        case GET_EC2_VPC_REPORT_METRICS:
            return setEc2VPC(state, action.payload);
        default:
            return state;
    }
}
