import * as React from 'react';
import { useSelector, RootStateOrAny } from "react-redux";
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Avatar from '@material-ui/core/Avatar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import { AddBox, Menu, MenuOpen } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { useFlag } from '@unleash/proxy-client-react';
import { pulse_text as pulseLogoUrl } from '../constants/links';
import { User } from '../types';
import headerStyles from './HeaderStyles';
import ImpersonationHeader from './ImpersonationHeader';

// global google analytics function
declare var ga: (actionType: string, dataObj: any) => any;

interface HeaderProps extends RouteComponentProps<any> {
  handleSidebarOpen: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  open: boolean
}

const SsoButton = withStyles({
  contained: {
    '&:hover': {
      backgroundColor: '#c5c5c5'
    }
  }
})(Button);

const StyledBreadcrumbs = withStyles({
  separator: {
    color: '#3A8BD8'
  },
  ol: {
    marginTop: '2px'
  }
})(Breadcrumbs);

const SECURITY = { name: 'Security', path: '/security' };
const OPERATIONS = { name: 'Operations', path: '/operations' };
const COSTS = { name: 'Costs', path: '/costs' }

export const ROUTES = {
  '/landscape': [{ name: 'Landscape', path: '/landscape' }],
  '/security': [ SECURITY ],
  '/dlp': [ SECURITY, { name: 'Data Loss Prevention for AWS', path: '/dlp' }],
  '/trendmicro': [ SECURITY, { name: 'Malware Detection', path: '/trendmicro' }],
  '/threatstack': [ SECURITY, { name: 'Threat Stack', path: '/threatstack' }],
  '/alertlogic': [ SECURITY, { name: 'Alert Logic', path: '/alertlogic' }],
  '/security-notifications': [ SECURITY, { name: 'Notifications', path: '/security-notifications' }],
  '/operations': [ OPERATIONS ],
  '/patching-report': [ OPERATIONS, { name: 'Patching', path: '/patching-report'}],
  '/backups-report': [ OPERATIONS, { name: 'Backups', path: '/backups-report' }],
  '/pulse-user-permissions-report': [OPERATIONS, { name: 'Pulse User Permissions', path: '/pulse-user-permissions-report' }],
  '/operations-notifications': [ OPERATIONS, { name: 'Notifications', path: '/operations-notifications' }],
  '/costs': [ COSTS ],
  '/cost-changes': [ COSTS, { name: 'Week-Over-Week', path: '/cost-changes'}],
  '/cloudhealth': [ COSTS, { name: 'Cost Optimization', path: '/cloudhealth' }],
  '/cost-notifications': [ COSTS, { name: 'Notifications', path: '/cost-notifications' }],
  '/profile': [{ name: 'Account', path: '/profile'}],
  '/notifications': [{ name: 'Notifications', path: '/notifications' }],
  '/support': [{ name: 'Support', path: '/support' }],
  '/events': [{ name: 'Alarms', path: '/events' }],
  '/impersonation-admin': [{ name: 'User Permissions', path: '/impersonation-admin'}],
  '/azure-service-map': [{ name: 'Azure Service Map', path: '/azure-service-map'}]
}


const Header = (props: HeaderProps) => {
  const modalIsOpen = useSelector((state: RootStateOrAny) => state.home.modalIsOpen);
  const permissions = useSelector((state: RootStateOrAny) => state.home.permissions);
  const isMobile = useSelector((state: RootStateOrAny) => state.home.isMobile);
  const isPhone = useSelector((state: RootStateOrAny) => state.home.isPhone);
  const user: User = useSelector((state: RootStateOrAny) => state.home.user);
  const impersonation = useSelector((state: RootStateOrAny) => state.home.impersonation);

  const cloud_jira_enabled = useFlag('cloud_jira');
  const ticket_button_enabled = useFlag('ticket_button_enabled')

  const classes = headerStyles();

  const handleServiceTicketButtonClick = (): void => {
    if (!impersonation) {
      gtag('event', "OutboundLinkWithSSO", {
        eventAction: 'Click',
        eventLabel: 'JiraServiceDesk'
      });
    }

    openJiraUrl();
  }

  const openJiraUrl = () => {
    if (window.waffle.SWITCHES['is_rapid_scale_link_active'] ) {
      window.location.href = permissions.ticketing_link
    }
    else {
      let jira_link = cloud_jira_enabled ? "https://lw-jira.atlassian.net/servicedesk/customer/portal/2" : "https://project.logicworks.net/plugins/servlet/saml/auth";
      window.open(jira_link);
    }
  }
  
  const capitalizeInitials = (user: User): string => {
    if (user.full_name) {
      return user.full_name.toUpperCase();
    }
    if (user.first_name) {
      return user.first_name.toUpperCase();
    }
    return "";
  }

  const getFontWeight = (path: string): React.CSSProperties => {
    if (props.location.pathname) {
      return props.location.pathname == path ?
        { fontWeight: '700', fontFamily: 'Poppins' } :
        { fontWeight: '500', fontFamily: 'Poppins' }
    }
    return {};
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        {impersonation ? <ImpersonationHeader /> : null}
        <Toolbar>
          {pulseLogoUrl ? <img src={pulseLogoUrl} className={classes.pulseLogo} /> : <div className={classes.pulseLogo} />}
          {!props.open ? <IconButton
            onClick={(event) => props.handleSidebarOpen(event)}
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.headerMenuIcon}
            id="iconButton"
          >
            <Menu fontSize="large" />
          </IconButton> : <IconButton
            onClick={(event) => props.handleSidebarOpen(event)}
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.headerMenuIcon}
          >
              <MenuOpen fontSize="large" />
            </IconButton>}
          <div></div>
          <StyledBreadcrumbs>
            <Link className={classes.breadCrumbLink} style={getFontWeight('/')} to="/">
              Home
            </Link>
            {(props.location.pathname && ROUTES[props.location.pathname]) &&
              ROUTES[props.location.pathname].map(route => {
                return (
                  <Link
                    className={classes.breadCrumbLink}
                    style={getFontWeight(route['path'])}
                    to={route['path']}
                  >
                    {route['name']}
                  </Link>
                )
              })}
          </StyledBreadcrumbs>
          <div className={classes.rightNavbar}>
            {permissions.jira &&
              <SsoButton
                className={classes.serviceTicketButton}
                disabled={!ticket_button_enabled}
                onClick={() => handleServiceTicketButtonClick()}
                variant="contained"
                startIcon={<AddBox />}
              >
                Open a service ticket
            </SsoButton>}
            <Link to="/profile" className={classes.avatarLink}>
              <Avatar
                alt={`${capitalizeInitials(user)}`}
                src="/broken-image.jpg"
                className={classes.avatar}
              />
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default withRouter(Header);
