import React, { useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import PulseUserAccess from '../components/operations/PulseUserAccess';
import pulseUserAccessReportingStyles from './PulseUserAccessReportingContainerStyles';


// enable is_impersonating variable to be defined on window object
declare global {
  interface Window {
    is_impersonating: any;
  }
}

const PulseUserAccessReportingContainer = () => {
  const classes = pulseUserAccessReportingStyles();
  const ref = useRef(true);

  useEffect(() => {
    if (ref.current && !window.is_impersonating) {
      gtag('event', 'PageView', {
        eventAction: 'View',
        eventLabel: 'PulseUserAccessReportPage'
      });
  
      gtag('event', 'page_view', {
        page_title: 'PulseUserAccessReportPage',
        page_location: '/pulse-user-permissions-report'
      });
      
      ref.current = false;
    }
  }, []);

  return (
    <Grid container sm={12} className={classes.pulseUserAccessReportingContainer}>
      <div className={classes.pulseUserAccessReportingBodyHeader}>
        Pulse User Permissions
      </div>
      <div className={classes.pulseUserAccessReportingBody}>
        <PulseUserAccess />
      </div>
    </Grid>
  );
};

export default PulseUserAccessReportingContainer;
