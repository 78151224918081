import { Permissions } from "../types";

export function is_user_service_map_admin(permissions: Permissions): boolean {
  return (permissions.client_settings == true) ? true : false
}

export function getCookie(name): string | null {
  let cookieValue: string | null = null;
  if (document.cookie && document.cookie !== '') {
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = (cookies[i]).trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function isEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export function flatten(inputArray) {
  const flattened: any[] = [];
  for (let i = 0; i < inputArray.length; ++i) {
    for (let j = 0; j < inputArray[i].length; ++j)
      flattened.push(inputArray[i][j]);
  }
  return flattened;
}

export function arrayMax(arr) {
  let len = arr.length, max = -Infinity;
  while (len--) {
    if (arr[len] > max) {
      max = arr[len];
    }
  }
  return max;
};


