const tooltipMapping = {
    'Pulse Enabled': 'The user has access to the Logicworks Pulse portal.',
    'Customer Organization': 'Organization name of associated cloud account(s).',
    'Service Desk': 'The user has access to the Jira service desk.',
    'Pulse Notification': 'The user is permitted to receive Pulse notifications and setup notification preferences.',
    'Service Notification': 'The user is the technical contact that will receive maintenance notifications or service outage information via email.',
    'CloudHealth': 'The user has access to the CloudHealth system.',
    'Alert Logic': ' The user has access to the Alert Logic portal.', 
    'Trend Micro Role': 'The user has access to the Trend Micro portal.', 
    'Threat Stack': 'The user has access to the Threat Stack portal.', 
    'Data Loss Prevention Role': 'Admin: User is permitted to access all DLP features. Notification: User is permitted to opt-in to notification but is read-only for auto-protect setting.',
    'Last Login': 'The time when the user last authenticated to Okta and access one of the systems.'
};

export default tooltipMapping;