export const requestConfig: RequestInit = {
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
    // 'Authorization': `JWT ${localStorage.getItem('jwtToken')}`
  },
  credentials: 'include'
};

export const getRequestConfig = (jwt: string = ''): RequestInit => {
  return {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
      // 'Authorization': `JWT ${jwt}`
    },
    credentials: 'include'
  };
}