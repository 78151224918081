import {
  GET_USER_CLOUD_ACCOUNTS
} from './types';
import { getRequestConfig } from '../../lib/auth';
import { inspectAndFetch } from '../../utilities/api';


export const getUserCloudAccounts = () => {
  return async (dispatch) => {
    const config = getRequestConfig()
    const response = await inspectAndFetch('/api/cloud-accounts/', config);
    const data = await response.json();

    dispatch({
      type: GET_USER_CLOUD_ACCOUNTS,
      payload: data
    })
  }
}
