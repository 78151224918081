import React, { useState } from 'react';
import { Dialog, Button, Checkbox, FormControlLabel, withStyles } from '@material-ui/core';

import rapidScaleWelcomeDialogStyles from './RapidScaleWelcomeDialogStyles';
import { rapidscale_banner } from '../../constants/links';

interface RapidScaleWelcomeDialogProps {
  rapidScaleWelcomeModalOpen: boolean
  closeRapidscaleWelcome: () => void
  checkRemoveRapidScaleWelcome: () => void
}

const StyledCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#084394',
    },
  },
})(Checkbox);

const RapidScaleWelcomeDialog = (props: RapidScaleWelcomeDialogProps) => {
  const [localCheckbox, setLocalCheckbox] = useState<boolean>(false);
  const classes = rapidScaleWelcomeDialogStyles();

  const handleChecboxChange = () => {
    setLocalCheckbox(!localCheckbox);
    props.checkRemoveRapidScaleWelcome();
  }

  return (
    <Dialog 
      open={props.rapidScaleWelcomeModalOpen}
      onClose={props.closeRapidscaleWelcome}
      transitionDuration={150}
      maxWidth="sm"
      style={{ fontFamily: 'CeraPro-Medium' }}
    >
      <div className={classes.rapidScaleWelcomeDialogContainer}>
        <div className={classes.rapidScaleWelcomeDialogBanner}>
          <img src={rapidscale_banner}/>
        </div>
        <div>
          <div className={classes.rapidScaleWelcomeDialogHeader}>
            Meet the NEW RapidScale!
          </div>
          <p className={classes.rapidScaleWelcomeDialogP1}>
            As you’re aware, Logicworks was acquired this year and is assuming the RapidScale brand. We're excited to introduce you to the new RapidScale and our comprehensive portfolio of private and public cloud solutions. Backed by one of the deepest benches of certified experts in the industry, our combined products and services are designed to keep your technology platforms fluid and responsive, making innovation fast and easy. Welcome to a new world of limitless possibilities!
          </p>
          <p className={classes.rapidScaleWelcomeDialogP2}>
            Learn more about our enhanced offerings at <a className={classes.rapidScaleWelcomeDialogLink} target="_blank" href="https://www.rapidscale.net">www.rapidscale.net</a>
          </p>
        </div>
        <div className={classes.rapidScaleWelcomeDialogCheckboxRow}>
          <FormControlLabel 
            control={<StyledCheckbox checked={localCheckbox} onChange={handleChecboxChange} style={{ color: '#084394'}}/>} 
            label="Do not show this again" 
          />
        </div>
        <div className={classes.rapidScaleWelcomeDialogButtons}>
          <Button onClick={props.closeRapidscaleWelcome} style={{ backgroundColor: '#0D70F7', color: 'white'}}>OK</Button>
        </div>
      </div>
    </Dialog>
  )
}


export default RapidScaleWelcomeDialog