import * as pbi from 'powerbi-client';
import * as models from 'powerbi-models';
import { reportToFilterValues } from "./csv";
import { setPatchingFilters } from '../actions/reporting';
import { getRequestConfig } from '../lib/auth';
import { inspectAndFetch } from './api';

export async function fetchToken(url, reportContainer, dispatch) {
  try {
    const config = getRequestConfig()
    const response: Response = await inspectAndFetch(url, config);
    const data: JSON = await response.json();
    const embedToken: string = data['token'];
    const reportId: string = data['report_id'];
    const embedUrl: string = data['report_embed_url']

    const token = embedToken;
    const embedConfiguration: pbi.IEmbedConfiguration = {
      type: 'report',
      id: reportId,
      embedUrl: embedUrl,
      tokenType: models.TokenType.Embed,
      accessToken: token,
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        background: models.BackgroundType.Transparent,
        // START Report specific settings
        layoutType: models.LayoutType.Custom,
        customLayout: {
          displayOption: models.DisplayOption.FitToWidth
        }
      }
    }

    if (reportContainer) {
      const powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      const report: any = powerbi.bootstrap(reportContainer, embedConfiguration);
      report.on("loaded", async () => {
        const values = await reportToFilterValues(report, "AWS Resources", ["Patch Group", "Environment", "Operating System", "VPC"], false);
        dispatch(setPatchingFilters(values));
      });
    }
  }
  catch (err) {
    dispatch(setPatchingFilters({}));
  }
}


export async function applyAllStatusPatchingFilter(report, nameOfVisual) {
  const allStatusFilter = {
    "$schema": "http://powerbi.com/product/schema#basic",
    "target": {
      "table": "EC2_Instance",
      "column": "CC_PatchingStatus"
    },
    "filterType": 1,
    "displaySettings": {
      "isHiddenInViewMode": false
    },
    "operator": "In",
    "values": [
      "Pending Maintenance",
      "Requires Attention",
      "Successfully Patched",
      "Excluded by Client Request"
    ],
    "requireSingleSelection": false
  }
  await _applyFilterToVisual(report, allStatusFilter, nameOfVisual)
}


export async function applyAllStatusBackUpsFilter(report, nameOfVisual) {
  const allStatusFilter = {
    "$schema": "http://powerbi.com/product/schema#basic",
    "target": {
      "table": "EC2_Instance_BackupReport",
      "column": "BackupStatus"
    },
    "filterType": 1,
    "displaySettings": {
      "isHiddenInViewMode": false
    },
    "operator": "In",
    "values": [
      "Not Configured",
      "Requires Attention",
      "Successfully Backed Up",
      "Excluded"
    ],
    "requireSingleSelection": false
  }
  await _applyFilterToVisual(report, allStatusFilter, nameOfVisual)

}


async function _applyFilterToVisual(report, filter, nameOfVisual) {
  const pages = await report.getPages();
  let page = pages.filter(page => page.isActive)[0];
  const visuals = await page.getVisuals()
  visuals.forEach((visual) => {
    if (visual.title === nameOfVisual) {
      visual.setFilters(filter)
    }
  })
}
