import * as CSV from 'csv-string';
import {models} from "powerbi-client";

async function reportToFilterValues(report, visualTitle, filterFields, toList) {
    const pages = await report.getPages()
    let data ;
    // for await ( const page of pages ){
    let page = pages.filter(page => page.isActive)[0];
    const visuals =  await page.getVisuals()
    for await(const visual of  visuals) {
        if (visual.title === visualTitle) {
            const visualData = await visual.exportData(models.ExportDataType.Summarized).then(data => {
                return data
            })
            data = await stringCSVToFilterValues(visualData.data, filterFields, toList)
            return data
        }
    }

}

function _csvValuesSetParse(index, parsedData, headerName,column, filterFields ){
    if (index !== 0) {
        let values = parsedData[headerName]
        if (values !== undefined) {
            parsedData[headerName].add(column)
        } else if (filterFields.includes(headerName)) {
            const value = new Set()
            value.add(column)
            parsedData[headerName] = value
        }
    }
    return parsedData
}

function _csvValuesListParse(index, parsedData, headerName,column, filterFields ){
    let values = parsedData[headerName]
    if (values !== undefined) {
        values.push(column)
        parsedData[headerName] = values
    } else if (filterFields.includes(headerName)) {
        let value: any = []
        value.push(column)
        parsedData[headerName] = value
    }
    return parsedData
}

function stringCSVToFilterValues(csvDataAsString: string, filterFields, toList: boolean) {
    const csvAsArray = CSV.parse(csvDataAsString) as Array<any>
    let parsedData = {}
    let csvHeaders = getHeaders(csvAsArray)
    const parser =  toList ? _csvValuesListParse : _csvValuesSetParse

    csvAsArray.forEach(function (record, rowIndex) {
        if (rowIndex !== 0) {
            record.forEach((column, index) => {
                const headerName = csvHeaders[index];
                parsedData = parser(index, parsedData, headerName, column, filterFields)
            })
        }
    })
    return parsedData
}

function getHeaders(csvAsArray): Object {

    const csvHeaders = {}
    const headers = csvAsArray[0]
    headers.forEach((headerName, index) => {
        csvHeaders[index] = headerName
    })
    return csvHeaders
}

export {
    reportToFilterValues
}
