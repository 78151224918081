import { makeStyles, createTheme, Theme, createStyles } from '@material-ui/core/styles';

const drawerWidth: number = 240;
const sidebarStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      backgroundColor: 'white',
      fontFamily: 'Roboto'
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: 'white',
      color: 'white',
      marginTop: '64px',
      overflowX: 'hidden',
      overflowY: 'hidden',
      fontFamily: 'Roboto',
      '&:hover': {
        overflowY: 'auto',
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      }
    },
    drawerPaperExpanded: {
      width: drawerWidth,
      backgroundColor: 'white',
      color: 'white',
      marginTop: '140px'
    },
    topList: {
      paddingTop: '0',
      paddingBottom: '0'
    },
    bottomList: {
      paddingTop: '0',
      paddingBottom: '120px'
    },
    listItem: {
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingRight: '0',
      fontSize: '14px',
      lineHeight: '17px',
      display: 'block',
      color: '#084394',
      cursor: 'pointer'
    },
    selectedListItem: {
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingRight: '0',
      fontSize: '14px',
      lineHeight: '17px',
      display: 'block',
      backgroundColor: '#0D70F7',
      color: 'white',
      cursor: 'pointer',
    },
    iconButton: {
      minHeight: '64px',
      fontSize: '12px',
      lineHeight: '14.5px',
      display: 'inline-flex',
      justifyContent: 'flex-start'
    },
    headerMenuIcon: {
      marginRight: '20px',
      alignItems: 'flex-end'
    },
    anchorTag: {
      textDecoration: 'none',
      display: 'inline-flex',
      alignItems: 'end',
      color: '#084394',
      width: '100%'
    },
    selectedAnchorTag: {
      textDecoration: 'none',
      display: 'inline-flex',
      alignItems: 'end',
      color: 'white',
      width: '100%'
    },
    listItemSpan: {
      lineHeight: '17px', 
      fontSize: '17px',
      color: '#0D70F7'
    },
    selectedListItemSpan: {
      lineHeight: '17px', 
      fontSize: '17px',
      color: 'white'
    },
    subList: {
      marginLeft: '-15px',
      marginTop: '-5px'
    },
    subListItem: {
      fontSize: '12px',
      lineHeight: '14.5px',
      cursor: 'pointer',
      "&:hover": {
        color: '#07EBF8'
      }
    },
    selectedSubListItem: {
      fontSize: '12px',
      lineHeight: '14.5px',
      backgroundColor: '#0D70F7',
      color: 'white',
      cursor: 'pointer',
      "&:hover": {
        color: '#07EBF8'
      }
    },
    subListItemInactive: {
      fontSize: '12px',
      lineHeight: '14.5px',
      cursor: 'pointer',
      color: 'white',
      opacity: '0.3'
    },
    iconRow: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '-15px',
      paddingLeft: '15px',
      paddingTop: '4px',
      paddingBottom: '4px'
    },
    iconRowSelected: {
      backgroundColor: '#0D70F7',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      marginLeft: '-15px',
      paddingLeft: '15px',
      paddingTop: '4px',
      paddingBottom: '4px'
    },
    notificationsItem: {
      display: 'flex',
      alignItems: 'center'
    }
  }),
);

const theme = createTheme({
  overrides: {
    MuiListItem: {
      "root": {
        '&$selected': {
          // Does not work:
          // background: 'red',

          // Does not work:
          // backgroundColor: 'red',

          // Works (without the need for !important)
          background: '#0D70F7',
          color: 'white'
          // Works (must use !important):
          // backgroundColor: 'red !important',

          // Works (must use !important):
          // background: 'red !important',
        }
      }
    }
  }
});


export default sidebarStyles;
