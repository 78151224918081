import {
  SET_PATCHING_FILTERS
} from '../actions/reporting/types';


export interface ReportingState {
  patchingFilters: Object
}

export const initialState: ReportingState = {
  patchingFilters: {}
}

function setPatchingFilters(prevState, data) {
  return { ...prevState, patchingFilters: data }
}


export default function (state: ReportingState = initialState, action) {
  switch (action.type) {
    case SET_PATCHING_FILTERS:
      return setPatchingFilters(state, action.payload);
    default:
      return state;
  }
}
