import {
  GET_CLOUD_ACCOUNT_INFO, UPDATE_CLOUD_ACCOUNT_INFO,
  FAILED_CLOUD_ACCOUNT_UPDATE,
  CLEAR_CLOUD_ACCOUNT_UPDATE,
  OPEN_DLP_MODAL, CLOSE_DLP_MODAL,
  SET_DLP_DATA_LOADING
} from '../actions/types';
import { CloudAccountSettings, CloudAccountNotifictions, SecurityOptions } from '../types';

export interface DLPState {
  settings: CloudAccountSettings[]
  notifications: CloudAccountNotifictions[]
  advanced_settings: string
  successfulUpdate: boolean
  failedUpdate: boolean
  updatingItem: number | null
  isModalOpen: boolean
  isDLPDataLoading: boolean
  modalIndex: number | null
  activeSecurityOptions: SecurityOptions | null
  activeValue: number | null
}

function setCloudAccountInfo(state, payload) {
  const settings = payload.settings.map(setting => {
    return { ...setting, params: setting.params ? JSON.parse(setting.params) : {} }
  });
  const notifications = payload.notifications.map(notification => {
    return { ...notification, params: notification.params ? JSON.parse(notification.params) : {} }
  });

  return { ...state, settings, notifications, isDLPDataLoading: false }
}

function resetCloudAccountInfo(state, payload) {
  if (payload.notification) {
    const itemToUpdate = state.notifications.find(notif => notif.notification === payload.notification);
    const updatedItem = { ...itemToUpdate, active: payload.active, params: payload.params ? JSON.parse(payload.params) : {} }
    const newNotifications = [...state.notifications.filter(notif => notif.notification !== payload.notification), updatedItem];

    return { ...state, notifications: newNotifications, successfulUpdate: true, failedUpdate: false, updatingItem: payload.index, isModalOpen: false }
  }
  else if (payload.setting) {
    const itemToUpdate = state.settings.find(setting => setting.setting === payload.setting);
    const updatedItem = { ...itemToUpdate, active: payload.active, params: payload.params ? JSON.parse(payload.params) : {} }
    const newSettings = [...state.settings.filter(setting => setting.setting !== payload.setting), updatedItem];

    return { ...state, settings: newSettings, successfulUpdate: true, failedUpdate: false, updatingItem: payload.index, isModalOpen: false }
  }
  else {
    return state
  }
}

export const initialState: DLPState = {
  settings: [], notifications: [],
  advanced_settings: '',
  successfulUpdate: false,
  failedUpdate: false,
  updatingItem: null,
  isModalOpen: false,
  isDLPDataLoading: false,
  modalIndex: null,
  activeSecurityOptions: null,
  activeValue: null
};

export default function (state: DLPState = initialState, action) {
  switch (action.type) {
    case SET_DLP_DATA_LOADING:
      return { ...state, isDLPDataLoading: true }
    case GET_CLOUD_ACCOUNT_INFO:
      return setCloudAccountInfo(state, action.payload);
    case UPDATE_CLOUD_ACCOUNT_INFO:
      return resetCloudAccountInfo(state, action.payload);
    case FAILED_CLOUD_ACCOUNT_UPDATE:
      return { ...state, successfulUpdate: false, failedUpdate: true, updatingItem: action.payload }
    case CLEAR_CLOUD_ACCOUNT_UPDATE:
      return { ...state, successfulUpdate: false, failedUpdate: false, updatingItem: null }
    case OPEN_DLP_MODAL:
      return {
        ...state,
        isModalOpen: true,
        modalIndex: action.payload.index,
        activeSecurityOptions: action.payload.securityOptions,
        activeValue: action.payload.value
      }
    case CLOSE_DLP_MODAL:
      return { ...state, isModalOpen: false }
    default:
      return state;
  }
}