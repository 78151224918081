import * as React from 'react';
import { Grid } from '@material-ui/core';

import styles from '../DefaultContainerStyles';

const UnauthorizedPage = () => {
  const classes = styles();

  return (
    <Grid container xs={12} style={{ justifyContent: 'center' }}>
      <div className={classes.errorContainer}>
        403 Error | User not authorized to view page
      </div>
    </Grid>
  )
}

export default UnauthorizedPage;
