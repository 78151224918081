import {
  GET_PERMISSIONS, UPDATE_TIMESTAMP,
  OPEN_MODAL, CLOSE_MODAL,
  SET_DEVICE_TYPE,
  SET_IMPERSONATION_INFO,
  UNSET_IMPERSONATION_INFO,
  SET_COST_DATA_AVAILABILITY,
  SET_EARLIEST_COST_DATE,
  SET_BASE_USER_INFORMATION,
  SET_OKTA_ID
} from '../actions/types';


import { openModal } from '../actions';
import store from '../store';
import { Permissions, Manager, User } from '../types';
import {StaticImageLink} from "../types/static/image-links";

declare global {
  interface Window {
    sessionTimer: any
  }
}

export interface HomeState {
  permissions: Permissions
  gravatar_url: string
  trendmicro_url: string | null
  trendmicro_role: string
  cloudhealth_url: string
  account_types: string[]
  manager: Manager
  user: User
  sessionTimer: any
  modalIsOpen: boolean
  isLoading: boolean
  isMobile: boolean
  isPhone: boolean
  isWindows: boolean
  impersonation: boolean
  impersonator: string,
  staticUrls: any
  cost_data_available: boolean
  loadingCostData: boolean
  earliest_cost_date: string
  client_id: string
  oidc_issuer: string
  client_url: string
  okta_id: string
}

window.sessionTimer = null;

function setPermissions(state, data) {
  const { account_types, permissions, user, manager, gravatar_url, cloudhealth_url,
    trendmicro_url, trendmicro_role, impersonation, impersonator } = data;
  window.sessionTimer = setTimeout(() => store.dispatch(openModal()), 6300000)
  let sessionTimer: any = window.sessionTimer;
  let modalIsOpen: boolean = false;
  let isLoading: boolean = false;

  return {
    ...state, permissions, user,
    manager, gravatar_url, cloudhealth_url,
    sessionTimer, modalIsOpen, isLoading,
    trendmicro_url, trendmicro_role, account_types,
    impersonation, impersonator
  }
}

function resetTimer(state) {
  clearTimeout(window.sessionTimer);
  window.sessionTimer = setTimeout(() => store.dispatch(openModal()), 6300000);
  let sessionTimer: any = window.sessionTimer;
  let modalIsOpen: boolean = false;

  return { ...state, sessionTimer, modalIsOpen }
}

function setImpersonationState(state, payload) {
  window.location.pathname = '/';
  return { ...state, impersonator: payload, impersonation: true }
}

function unsetImpersonationState(state) {
  window.location.pathname = '/';
  return { ...state, impersonator: '', impersonation: false }
}

function setCostDataAvailability(state, payload) {
  return {...state, ...payload, loadingCostData: false }
}

function setEarliestCostDate(state, payload) {
  const { EarliestDate } = payload
  return { ...state, earliest_cost_date:  EarliestDate }
}

function setBaseUserInfo(state, payload) {
  const { client_id, client_url, oidc_issuer } = payload
  return { ...state, client_id, client_url, oidc_issuer }
}

function setOktaId(state, payload) {
  return { ...state, okta_id: payload }
}

export const initialState: HomeState = {
  permissions: {},
  gravatar_url: '',
  trendmicro_url: null,
  trendmicro_role: '',
  cloudhealth_url: '',
  account_types: [],
  manager: {},
  user: {},
  sessionTimer: null,
  modalIsOpen: false,
  isLoading: true,
  isMobile: false,
  isPhone: false,
  isWindows: false,
  impersonation: false,
  impersonator: '',
  staticUrls:  {},
  cost_data_available: false,
  loadingCostData: true,
  earliest_cost_date: "",
  client_id: "",
  client_url: "",
  okta_id: "",
  oidc_issuer: ""
}

export default function(state: HomeState = initialState, action) {
  switch (action.type) {
    case GET_PERMISSIONS:
      return setPermissions(state, action.payload);
    case OPEN_MODAL:
      return { ...state, modalIsOpen: true };
    case CLOSE_MODAL:
      return { ...state, modalIsOpen: false };
    case UPDATE_TIMESTAMP:
      return resetTimer(state);
    case SET_DEVICE_TYPE:
      return { ...state,
        isMobile: window.innerWidth <= 1000,
        isPhone: window.innerWidth <= 500,
        isWindows: window.navigator.platform === 'Win32'
      }
    case SET_IMPERSONATION_INFO:
      return setImpersonationState(state, action.payload);
    case UNSET_IMPERSONATION_INFO:
      return unsetImpersonationState(state);
    case SET_COST_DATA_AVAILABILITY:
      return setCostDataAvailability(state, action.payload)
    case SET_EARLIEST_COST_DATE:
      return setEarliestCostDate(state, action.payload)
    case SET_BASE_USER_INFORMATION:
      return setBaseUserInfo(state, action.payload)
    case SET_OKTA_ID:
      return setOktaId(state, action.payload)
    default:
      return state;
  }
}
