import * as React from 'react';
import { useState, useEffect, lazy, Suspense } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';

import clsx from 'clsx';
import { Button, Grid, CircularProgress, Dialog } from '@material-ui/core';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
const HomeContainer = lazy(() => import('./containers/HomeContainer'));
const SupportContainer = lazy(() => import('./containers/SupportContainer'));
const ProfileContainer = lazy(() =>  import('./containers/profile/ProfileContainer'));
const NotificationsContainer = lazy(() => import('./containers/NotificationsContainer'));
const CostNotificationsContainer = lazy(() => import('./containers/CostNotificationsContainer'));
const OperationsContainer = lazy(() => import('./containers/OperationsContainer'));
const BackupsReportContainer = lazy(() => import('./containers/BackupsReportingContainer'));
const OperationsNotificationsContainer = lazy(() => import('./containers/OperationsNotificationsContainer'));
const LandscapeContainer = lazy(() => import('./containers/LandscapeContainer'));
const SecurityContainer = lazy(() => import('./containers/SecurityContainer'));
const SecurityNotificationsContainer = lazy(() => import('./containers/SecurityNotificationsContainer'));
const CostsContainer = lazy(() => import('./containers/CostsContainer'));
const CostChangesContainer = lazy(() => import('./containers/CostChangesContainer'));
const MonthlyCostChangesContainer = lazy(() => import('./containers/MonthlyCostChangesContainer'));
const AlertlogicContainer = lazy(() => import('./containers/AlertlogicContainer'));
const CloudhealthContainer = lazy(() => import('./containers/CloudhealthContainer'));
const TrendmicroContainer = lazy(() => import('./containers/TrendmicroContainer'));
const ThreatStackContainer = lazy(() => import('./containers/ThreatStackContainer'));
const DlpContainer = lazy(() => import('./containers/DlpContainer'));
const AdminContainer = lazy(() => import('./containers/AdminContainer'));
const PatchingReportContainer = lazy(() => import("./containers/PatchingReportingContainer"));
import PulseUserAccessReportingContainer from './containers/PulseUserAccessReportingContainer';

import RapidScaleWelcomeDialog from './components/global/RapidScaleWelcomeDialog';
import ProtectedRoute from './hocs/ProtectedRoute';
import {
  getPermissions, getNotifications, getNotificationLastScans, setDeviceType, loadCloudCostDataAsync,
  getCostDataAvailability, getNotificationDetails, getEarliestCostDate
} from './actions';
import { getUserCloudAccounts } from './actions/accounts';
import { fetchToken } from './utilities/power_bi_data';
import { patchingFilters } from './constants/links';
import { Permissions } from './types'
import containerStyles from './containers/DefaultContainerStyles';
import config from './okta-config';
import { useFlag } from '@unleash/proxy-client-react';

// enable single_permission_url variable to be defined on window object
declare global {
  interface Window {
    single_permission_url: any
    waffle: any
    CLIENT_ID: string
  }
}

const drawerWidth: number = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
      padding: 0
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
  }),
);

let initialPageLoaded: boolean = false;


const AppContainer = (props: RouteComponentProps) => {
  // configure admin config API okta auth based on environment
  const issuer = useSelector((state: RootStateOrAny) => state.home.oidc_issuer);
  const client_id = useSelector((state: RootStateOrAny) => state.home.client_id);
  config.oidc['clientId'] = client_id;
  config.oidc['issuer'] = issuer;

  let initialOpenState: boolean = true;
  if (!initialPageLoaded && props.location.pathname === "/" ) {
    initialOpenState = false;
  }
  const classes = useStyles();
  const containerClasses = containerStyles();
  const [open, setOpen] = useState<boolean>(initialOpenState);
  const [rapidScaleWelcomeModalOpen, setRapidScaleWelcomeModalOpen] = useState<boolean>(false);
  const [rapidScaleWelcomeCheck, setRapidScaleWelcomeCheck] = useState<boolean>(false);
  const impersonation = useSelector((state: RootStateOrAny) => state.home.impersonation);
  const permissions: Permissions = useSelector((state: RootStateOrAny) => state.home.permissions);
  const isLoading: boolean = useSelector((state: RootStateOrAny) => state.home.isLoading);
  const dispatch = useDispatch();
  const feature_flag_pulse_user_access_enabled = useFlag('lw_pulse_user_access_enabled');

  const checkRemoveRapidScaleWelcome = () => {
    setRapidScaleWelcomeCheck(!rapidScaleWelcomeCheck);
  }

  const closeRapidscaleWelcome = () => {
    if (rapidScaleWelcomeCheck) {
      // storage information that user has acknowledged modal information
      // so modal doesn't show next time user comes to site
      localStorage.setItem('rapidScaleWelcomeAcknowledged', "true");
    }
    // close modal temporarily, whether or not user has explicitly acknowledged
    setRapidScaleWelcomeModalOpen(false);
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(setDeviceType())
    })
    dispatch(getPermissions());
    dispatch(getCostDataAvailability());
    dispatch(getNotifications());
    dispatch(getNotificationDetails());
    dispatch(getNotificationLastScans());
    dispatch(getUserCloudAccounts());
    dispatch(setDeviceType());
    dispatch(getEarliestCostDate());
    loadCloudCostDataAsync()
    const el = document.getElementById('patching-filters');
    fetchToken(patchingFilters, el, dispatch);

    const _rapidScaleWelcomeAck = localStorage.getItem('rapidScaleWelcomeAcknowledged');
    setRapidScaleWelcomeModalOpen(_rapidScaleWelcomeAck === 'true' ? false : true);

    if (window.single_permission_url) {
      props.history.push(window.single_permission_url)
    }
  }, [])

  const handleSidebarOpen = e => {
    setOpen(!open)
  }

  console.log('permissions in AppContainer:', permissions)

  return (
    <div style={{ fontFamily: 'Lato' }}>
      <Header open={open} handleSidebarOpen={handleSidebarOpen} />
      <Sidebar open={open} handleSidebarOpen={handleSidebarOpen} />
      <div
        style={{ marginTop: impersonation ? 140 : 50 }}
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
          <Suspense fallback={
            <Grid container xs={12} className={containerClasses.loadingContainer}>
              <CircularProgress size={80} color="inherit"/>
            </Grid>}>
            <Switch>
              <Route exact path="/" render={(props) => <HomeContainer open={open} {...props} />} />
              <Route exact path="/support" render={() => <SupportContainer />} />
              <Route exact path="/profile" render={() => <ProfileContainer />} />
              <Route exact path="/notifications" render={() => <ProtectedRoute component={NotificationsContainer} authorized={permissions.notifications || false} isLoading={isLoading} />} />
              <Route exact path="/landscape" render={() => <LandscapeContainer />} />
              <Route exact path="/security" render={() => <SecurityContainer />} />
              <Route exact path="/security-notifications" render={() => <ProtectedRoute component={SecurityNotificationsContainer} authorized={permissions.notifications || false} isLoading={isLoading} />} />
              <Route exact path="/operations" render={() => <OperationsContainer />} />
              <Route exact path="/backups-report" render={() => <ProtectedRoute component={BackupsReportContainer} authorized={permissions.backups_report || false} isLoading={isLoading} />} />
              <Route exact path="/patching-report" render={() => <ProtectedRoute component={PatchingReportContainer} authorized={permissions.patching_report || false} isLoading={isLoading} />} />
              <Route exact path="/pulse-user-permissions-report" render={() => <ProtectedRoute component={PulseUserAccessReportingContainer} authorized={feature_flag_pulse_user_access_enabled && (permissions.pulse_user_access_report  || false)} isLoading={isLoading} />} />
              <Route exact path="/operations-notifications" render={() => <ProtectedRoute component={OperationsNotificationsContainer} authorized={permissions.notifications || false} isLoading={isLoading} />} />
              <Route exact path="/costs" render={(props) => <CostsContainer open={open} {...props} />} />
              <Route exact path="/cost-changes" render={() => <CostChangesContainer />} />
              <Route exact path="/cost-changes-monthly" render={() => <MonthlyCostChangesContainer />} />
              <Route exact path="/cost-notifications" render={() => <ProtectedRoute component={CostNotificationsContainer} authorized={permissions.notifications || false} isLoading={isLoading} />} />
              <Route exact path="/dlp" render={() => <DlpContainer />} />
              <Route exact path="/cloudhealth" render={() => <CloudhealthContainer />} />
              <Route exact path="/alertlogic" render={() => <AlertlogicContainer />} />
              <Route exact path="/trendmicro" render={() => <TrendmicroContainer />} />
              <Route exact path="/threatstack" render={() => <ThreatStackContainer />} />
              <Route exact path="/impersonation-admin" render={() => <ProtectedRoute component={AdminContainer} authorized={permissions.can_impersonate || false} isLoading={isLoading} />} />
            </Switch>
          </Suspense>
      </div>
      <RapidScaleWelcomeDialog 
        rapidScaleWelcomeModalOpen={rapidScaleWelcomeModalOpen}
        closeRapidscaleWelcome={closeRapidscaleWelcome}
        checkRemoveRapidScaleWelcome={checkRemoveRapidScaleWelcome}
      />
      <div id="patching-filters" style={{ display: 'none'}}/>
    </div>
  )
}

export default withRouter(AppContainer);
