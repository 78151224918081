import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth: number = 240;
const headerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      backgroundColor: 'white',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      filter: 'drop-shadow(0px 4px 4px)',
      zIndex: 10000
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    breadCrumbLink: {
      color: '#3A8BD8',
      fontFamily: 'Poppins',
      textDecoration: 'none',
      cursor: 'pointer',
      weight: 500,
      fontSize: '14px',
      lineHeight: '18px'
    },
    rightNavbar: {
      marginLeft: 'auto',
      display: 'flex'
    },
    serviceTicketButton: {
      color: 'white',
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontWeight: 500,
      lineHeight: '18.75px',
      align: 'center',
      backgroundColor: '#0D70F7',
      borderRadius: 0,
    },
    headerMenuIcon: {
      fontSize: '16px',
      marginRight: '30px',
      color: 'black'
    },
    headerProfileIcon: {
      marginLeft: 'auto',
      backgroundColor: '#3AAEFB'
    },
    avatarLink: {
      fontFamily: 'Roboto',
      weight: 400,
      fontSize: '20px',
      lineHeight: '23px',
      marginLeft: '15px',
      textDecoration: 'none'
    },
    avatar: {
      marginLeft: '35px',
      backgroundColor: '#071C8E'
    },
    pulseLogo: {
      marginRight: '40px',
      marginLeft: '-15px',
      width: '9rem'
    }
  }),
);

export default headerStyles;
